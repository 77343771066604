/*===========================
 =  Table of Contents
 0. Keyframes
 1. General
 1.1. Typography
 1.2. Section spacings
 1.3. Forms
 1.4. Buttons
 1.5. Colors & Badges
 1.6. Modals, Popups & Dropdowns
 1.7. Tables
 1.8. Tabs
 1.9. Social media links
 2. Header
 2.1. Header style 2
 2.2. Header style 3
 3. Footer
 5. Subheader
 5. Components
 6.. Loaders
 6.1. Blog Posts
 6.1.1. Blog Posts List
 6.1.2. Blog Post Details
 6.2. sidebar
 6.3. Products
 6.3.1. Products (List view)
 6.3.2 Products (Minimal View)
 6.3.3 Products Quick view & details
 6.3.4. Cart & Wishlist
 6.3.5. Checkout
 6.3.6 Products (Recent View)
 6.4. Categories Megamenu
 6.5. Banners
 6.6. Icon Blocks
 6.7. DONNATE TO HELPs
 6.8.Login & Signup
 6.9. Error 404
 6.10. Testimonials
 6.11. Companies
 6.12. Newsletter Popup
 7. Misc
 8. Responsive
===========================*/
/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/*-------------------------------------------------------------------*/
/* === Keyframes === */
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}
@keyframes pulseInOut{
  0%{opacity:1;transform:scale(.3)}
  100%{opacity:0;transform:scale(1.3)}
}
@keyframes pulseInOutSM{
  0%{opacity:1;transform:scale(.3)}
  100%{opacity:0;transform:scale(1.7)}
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes preloaderScale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
@keyframes preloaderScale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes BounceUpDown {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-2px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes BounceDownUp {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes bannerscale {
    50% {
        transform: scale(1.2);
    }
}

/*======================
1.0. General
========================*/

.z-index-3{
  z-index: 3;
}

.img-group{
  position: relative;
  text-align: center;
  padding: 80px;
}
.img-group-inner{
  position: relative;
}
.img-group-inner span{
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #A71B19;
}
.img-group-inner span + span{
  top: auto;
  right: auto;
  left: -10px;
  bottom: -10px;
  background-color: #fff;
}
.img-group img:nth-child(2){
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.img-group img:nth-child(3){
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.img-group-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-group-2 img + img{
  margin-left: auto;
  margin-top: -50%;
}

.border{
  border: 2px solid #efefef !important;
}
.vertical-seperator{
  display: block;
  width: 3px;
  height: 80px;
  text-align: center;
  background-color: #F74F22;
  margin: 0 auto;
}

.sigma_img-box{
  display: flex;
  flex-wrap: wrap;
}

.sigma_img-box img + img{
  margin-top: 40px;
  margin-left: 30px;
}

.section-before{
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  z-index: -1;
  background-color: #022147;
}
.section-before::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
}

.sigma_dots{
  width: 200px;
  height: 200px;
  content: '';
  background-position: 0 0,6px 6px;
  background-size: 12px 12px;
  position: absolute;
  display: block;
  background-image: -o-radial-gradient(#d1d4d8 1px,transparent 1px),-o-radial-gradient(#d1d4d8 1px,transparent 1px);
  background-image: radial-gradient(#d1d4d8 1px,transparent 1px),radial-gradient(#d1d4d8 1px,transparent 1px);
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  background-color: transparent;
  top: -250px;
  left: -100px;
  opacity: 1;
  z-index: -1;
}
.sigma_dots.dots-2{
  width: 100px;
  left: auto;
  height: 200px;
  right: 0;
  top: 100%;
}
.sigma_dots.primary{
  background-image: -o-radial-gradient(#F74F22 1px,transparent 1px),-o-radial-gradient(#F74F22 1px,transparent 1px);
  background-image: radial-gradient(#F74F22 1px,transparent 1px),radial-gradient(#F74F22 1px,transparent 1px);
}
.sigma_dots.secondary{
  background-image: -o-radial-gradient(#022147 1px,transparent 1px),-o-radial-gradient(#022147 1px,transparent 1px);
  background-image: radial-gradient(#022147 1px,transparent 1px),radial-gradient(#022147 1px,transparent 1px);
}


.bottom-skew{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.texture-4{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.texture-3{
  position: absolute;
  top: -20px;
  left: 0;
}
.texture-5{
  position: absolute;
  top: -20px;
  right: 30%;
  z-index: -1;
  width: 500px;
}
.sigma_banner .texture-5{
  top: -200px;
}

.circles .circle{
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: block;
}

.circles .circle.circle-lg{
  width: 290px;
  height: 290px;
}
.circles .circle.circle-md{
  width: 160px;
  height: 160px;
}
.circles .circle.circle-sm{
  width: 100px;
  height: 100px;
}

.sigma_shapes.irregular .irregular-1 svg{
  width: 1020px;
  position: absolute;
  top: -20px;
  right: 0;
}

.sigma_shapes.irregular .irregular-2{
  position: absolute;
  top: -320px;
  left: -430px;
  width: 500px;
  height: 500px;
  transform: rotate(-15deg);
  border-radius: 80px;
}

.sigma_shapes.irregular .irregular-3 svg{
  position: absolute;
  bottom: 0px;
  right: -610px;
  width: 920px;
}

.sigma_shapes.irregular .irregular-4 svg{
  position: absolute;
  right: -270px;
  top: -40px;
  width: 980px;
  z-index: -1;
}

.sigma_shapes.irregular .irregular-5{
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  bottom: -150px;
  left: -150px;
  border: 40px solid #F74F22;
  z-index: -1;
}

.sigma_shapes.irregular .irregular-6 svg{
  position: absolute;
  right: 0;
  bottom: -140px;
  width: 1060px;
  z-index: -1;
}

.custom-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.custom-img-1{
  position: absolute;
  bottom: -210px;
  right: 0;
  z-index: -3;
}

.spacer{
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.spacer.spacer-lg{
  height: 295px;
}
.spacer.spacer-xl{
  height: 500px;
}
.spacer.spacer-bottom{
  top: auto;
  bottom: 0;
}

.spacer.spacer-vertical{
  height: 100%;
  width:  140px;
}

.spacer.spacer-vertical.spacer-right{
  right: 0;
  left: auto;
}
.spacer.spacer-vertical.spacer-left{
  right: auto;
  left: 0;
}

.spacer.spacer-vertical.spacer-xl{
  width: 500px;
}

.sigma_canvas canvas,
.sigma_canvas{
  width: 100%;
}
.sigma_map{
  height: 600px;
  width: 100%;
}
.sigma_map .sigma_contact-info{
  left: auto;
  right: 0;
}
.sigma_map iframe{
  width: 100%;
  height: 100%;
}
.sigma_seperator-vertical{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sigma_seperator-vertical span{
  width: 1px;
  height: 200px;
  background-color: #efefef;
  display: block;
}
.sigma_seperator-vertical span:first-child,
.sigma_seperator-vertical span:last-child{
  margin: 0 20px;
}
.sigma_seperator-vertical span:nth-child(2){
  height: 400px;
}

.stroke-text{
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-weight: 400;
}

.stroke-text.stroke-text-dark{
  -webkit-text-stroke-color: #022147;
}

.stroke-text span{
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 0;
}
.stroke-text span.custom-primary{
  -webkit-text-fill-color: #F74F22;
  -webkit-text-stroke-width: 0;
}
.stroke-text span.text-dark{
  -webkit-text-fill-color: #022147;
  -webkit-text-stroke-width: 0;
}

.sigma_preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F74F22;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: .3s;
}

.sigma_preloader.hidden{
  opacity: 0;
  visibility: hidden;
}

.sigma_preloader img{
  animation: bannerscale 3s linear infinite;
}

.sigma_preloader ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  -webkit-animation: rot 16s linear infinite;
          animation: rot 16s linear infinite;
}
.sigma_preloader li {
  width: 40px;
  height: 40px;
  background: #F74F22;
  border-radius: 4px;
  box-shadow: 0 0 1px #fff, 0 0 5px #F74F22, 0 0 10px #F74F22, 0 0 15px #F74F22, 0 0 25px #F74F22, 0 0 55px #F74F22;
  -webkit-animation: preloaderScale 0.8s linear alternate infinite;
          animation: preloaderScale 0.8s linear alternate infinite;
}

.sigma_preloader li:nth-child(1) {
  z-index: 24;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.sigma_preloader li:nth-child(2) {
  z-index: 23;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sigma_preloader li:nth-child(3) {
  z-index: 22;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(4) {
  z-index: 21;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(5) {
  z-index: 20;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(6) {
  z-index: 19;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sigma_preloader li:nth-child(7) {
  z-index: 18;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(8) {
  z-index: 17;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(9) {
  z-index: 16;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(10) {
  z-index: 15;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(11) {
  z-index: 14;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sigma_preloader li:nth-child(12) {
  z-index: 13;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(13) {
  z-index: 12;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(14) {
  z-index: 11;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(15) {
  z-index: 10;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(16) {
  z-index: 9;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sigma_preloader li:nth-child(17) {
  z-index: 8;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(18) {
  z-index: 7;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(19) {
  z-index: 6;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(20) {
  z-index: 5;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.sigma_preloader li:nth-child(21) {
  z-index: 4;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sigma_preloader li:nth-child(22) {
  z-index: 3;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sigma_preloader li:nth-child(23) {
  z-index: 2;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.sigma_preloader li:nth-child(24) {
  z-index: 1;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.sigma_preloader li:nth-child(25) {
  z-index: 0;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #F74F22;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .50;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.pattern-building,
.pattern-squares,
.pattern-map,
.primary-overlay,
.light-overlay,
.secondary-overlay,
.dark-overlay {
    position: relative;
    z-index: 1;
}
.pattern-building::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/building.png);
  background-size: cover;
  background-position: center;
}
.pattern-map::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/map-2.png);
  background-size: cover;
  background-position: center;
}
.pattern-squares::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/squares.png);
  background-size: cover;
  background-position: center;
}
.pattern-triangles::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../img/textures/triangles.png);
  background-size: cover;
  background-position: center;
}


.dark-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.light-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, .9);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.primary-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(18 29 49 / 73%);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.secondary-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(2 33 71 / 62%);
    z-index: -1;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}


.dark-overlay.primary-overlay::before {
  background-color: rgba(72, 172, 110, .8);
}

.dark-overlay.dark-overlay-2::before {
    background-color: rgba(0, 0, 0, .2);
}
.dark-overlay.dark-overlay-3::before {
    background-color: rgba(0, 0, 0, .4);
}

.sigma_sticky-section {
    position: sticky;
    top: 30px;
}

html {
    overflow-x: hidden;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.9;
    font-weight: 400;
    color: #767e88;
    overflow-x: hidden;
}

hr {
    margin: 30px 0;
}

img {
    max-width: 100%;
    height: auto;
}

.imgs-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.imgs-wrapper img {
    position: absolute;
    max-width: 300px;
}

.imgs-wrapper img:nth-child(1) {
    top: 150px;
    max-width: 400px;
    left: -200px;
}

.imgs-wrapper img:nth-child(2) {
    right: -100px;
    bottom: 0;
}

.sigma_single-img-wrapper {
    position: relative;
}

.sigma_single-img-wrapper img {
    border-radius: 8px;
}

.signature {
    width: 220px;
    margin-bottom: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    outline: none;
}

.close-btn:focus {
    outline: none;
}

.close-btn span {
    position: absolute;
    width: 2px;
    height: 15px;
    display: block;
    background-color: #fff;
    opacity: 0.6;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.close-btn.close-dark span {
    background-color: #022147;
}

.close-btn.close-danger span {
    background-color: red;
}

.close-btn span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.close-btn:hover {
  transform: rotate(45deg);
}

.close-btn.dark span {
    background-color: #022147;
}

.icon-2x {
    font-size: 2rem;
}

.icon-3x {
    font-size: 3rem;
}

.icon-4x {
    font-size: 4rem;
}

.border-0 {
    border: 0;
}

.bg-cover {
    background-size: cover;
}

.bg-lg {
    background-size: 600px;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-contain {
    background-size: contain;
}

.bg-norepeat {
    background-repeat: no-repeat;
}

.bg-center {
    background-position: center;
}

.bg-left {
    background-position: left;
}

.bg-right {
    background-position: right;
}

.bg-top {
    background-position: top;
}

.bg-bottom {
    background-position: bottom;
}
.bg-bottom-left {
    background-position: bottom left;
}
.bg-bottom-right {
    background-position: bottom right;
}

.container-fluid{
  padding-left: 150px;
  padding-right: 150px;
}

.sigma_menu-vertical{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.sigma_menu-vertical li,
.sigma_menu-vertical li + li{
  margin: 0;
}
.sigma_menu-vertical li a{
  padding: 40px 20px;
  display: block;
  color: #777777;
  text-transform: uppercase;
}
.sigma_menu-vertical li a:hover{
  color: #fff;
}

/*======================
1.1. Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin: 0 0 20px;
  line-height: 1.2;
  color: #022147;
  font-weight: 700;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4 {
  font-size: 28px;
  line-height: 1.4;
}

h5 {
  font-size: 24px;
  line-height: 1.3;
}

h6 {
  font-size: 18px;
  line-height: 1.7;
}

p {
  font-size: 16px;
  line-height: 1.9;
  margin-bottom: 15px;
  color: #777;
}

a {
  color: #A71B19;
  text-decoration: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #022147;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h5 a:focus,
h6 a:focus {
  color: #A71B19;
}

a:hover,
a:focus {
    color: #ec6a47;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

b,
strong {
    font-weight: 700;
    color: #022147;
}

b span,
strong span{
  color: #777;
}

label {
    margin-bottom: 10px;
    color: #022147;
    font-weight: 600;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
}

ul li,
ol li {
    margin: 10px 0 0;
    position: relative;
}
.sigma_list{
  margin: 40px 0;
}
.sigma_list.list-2{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.sigma_list li {
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: #022147;
  font-weight: 600;
}

.sigma_list li+li {
    margin-top: 20px;
}

.sigma_list li::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-rendering: auto;
  line-height: 1;
  font-size: 20px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  margin-right: 10px;
  color: #F74F22;
  background-color: #e6e8e7;
}

.sigma_list.sigma_list-2 li{
  font-weight: 400;
  font-size: 14px;
}

.sigma_list.sigma_list-2 li::before{
  width: auto;
  height: auto;
  background-color: transparent;
}

.small,
small {
  line-height: 1.7;
}

.blockquote,
blockquote {
  position: relative;
  margin: 20px 0;
  font-size: 14px;
  color: #022147;
  padding: 10px 30px;
  background-position: center;
  background-size: cover;
  background-color: #f7f7f7;
  border-left: 2px solid #A71B19;
}

p.blockquote:before{
 content: "";
 position: absolute;
 left: -14px;
 top: 20px;
 width: 26px;
 height: 2px;
 background-color: #A71B19;
}

p.blockquote.light-border:before{
  background-color: rgba(255,255,255,.4);
}

.blockquote.light{
  background-color: transparent;
  color: #fff;
}
.blockquote.bg-transparent{
  color: #777;
}
.blockquote.light-border,
blockquote.light-border{
  border-left-color: rgba(255,255,255,.4);
}

blockquote{
  border-left: 0;
  text-align: center;
  padding: 50px;
}

.entry-content blockquote p,
blockquote p {
  position: relative;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  color: #022147;
}

blockquote cite{
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  color: #F74F22;
  display: block;
  margin: 0 0 10px;
}

blockquote::before{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 240px;
  content: "\f105";
  color: #fff;
  font-family: flaticon;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}


.entry-content p,
.entry-content img {
    margin-bottom: 20px;
}

.entry-content .sigma_post-share {
    margin-bottom: 20px;
}

.entry-content p a{
  font-weight: 600;
  text-decoration: underline;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.border-25 {
    border-radius: 25px;
}

.sigma_image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

/*======================
1.2 Section Spacings
========================*/
.section {
    position: relative;
    padding: 110px 0;
}
.section .container{
  position: relative;
}

.section.section-padding {
    padding: 110px 0 80px;
}
.section.section-lg{
  padding: 110px 0 200px;
}
.section.section-md{
  padding: 110px 0 160px;
}


.sigma_section-fw {
    padding: 0 80px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}

.me-lg-30 {
    margin-left: 30px;
}

.section.section-padding.category_section {
    margin-top: -50px;
    padding-top: 0;
}
.section-title .badge{
  margin-bottom: 10px;
}

.section-title .subtitle {
  position: relative;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: #A71B19;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-title.text-start .subtitle{
    justify-content: flex-start;
}

.section-title.text-md-right .subtitle,
.section-title.text-end .subtitle{
  justify-content: flex-end;
}

.section-title.text-md-right,
.section-title.text-end{
  margin: 0 0 50px auto;
}

.section-title.text-md-right .subtitle::after,
.section-title.text-end .subtitle::after,
.section-title.text-start .subtitle::after{
  display: none;
}

.section-title .subtitle::after,
.section-title .subtitle::before{
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 14px;
  margin-right: 10px;
}

.section-title .subtitle::after{
  margin-right: 0;
  margin-left: 10px;
}

.section-title p{
  font-size: 16px;
}

.section-title.section-title-2{
  position: relative;
}
.section-title.section-title-2 .subtitle{
  color: #F74F22;
  background-color: #e6e8e7;
  display: inline-block;
  padding: 5px 20px;
  font-size: 13px;
}
.section-title.section-title-2 .subtitle.light{
  background-color: rgba(255,255,255,.4);
}
.section-title.section-title-2 .subtitle::before,
.section-title.section-title-2 .subtitle::after{
  display: none;
}
.small-text{
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 16px;
}

.section-title .title {
    position: relative;
    margin: 0;
    font-size: 38px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
}

.section-title {
    position: relative;
    margin-bottom: 50px;
    max-width: 500px;
}
.section-title.text-center{
  margin: 0 auto 20px;
}

.section-title.flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
}

.section-title.flex-title .nav {
    margin-left: auto;
}

.section-title.flex-title .nav-item {
    margin: 0;
}

.section-title.flex-title .nav-item+.nav-item {
    margin-left: 10px;
}

.section-title.flex-title .nav-item .nav-link {
    margin: 0;
}

.section-button{
  margin-top: 30px;
}

.ov-hidden {
    overflow: hidden;
}

/*==================
1.3. Forms
====================*/
textarea {
    resize: none;
}

textarea.form-control {
    height: auto;
}

.form-control::-webkit-input-placeholder {
    color: #a5a5a5;
}

.form-control::-moz-placeholder {
    color: #a5a5a5;
}

.form-control:-ms-input-placeholder {
    color: #a5a5a5;
}

.form-control::-ms-input-placeholder {
    color: #a5a5a5;
}

.form-control::placeholder {
    color: #a5a5a5;
}

.form-control {
    height: 60px;
    padding: 8px 15px;
    border-radius: 0;
    border-radius: 0;
    width: 100%;
    color: #fff;
    background-color: #fff;
    letter-spacing: 0.1px;
    font-size: 14px;
    appearance: auto;
    -webkit-appearance: auto;
}

.form-control.transparent{
  background-color: rgba(255,255,255,.1);
  border: 0;
}
.form-control.transparent:focus{
  background-color: rgba(255,255,255,.2);
  border: 0;
}

.form-control.dark{
  background-color: #f7f7f7;
  border: 0;
}
.form-control.dark:focus{
  background-color: #eaeaea;
  border: 0;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #efefef;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-group i{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #e8e8e8;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row>.col, 
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input,
.input-with-icon select {
    padding-left: 40px;
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #a5a5a5;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.input-with-icon input:focus+i,
.input-with-icon select:focus+i {
    color: #454545;
}

/*Checkboxes and radios*/
input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"]+label,
input[type="radio"]+label {
  display: block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-weight: 400;
}

input[type="checkbox"]+label:before,
input[type="radio"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #808080;
  border-radius: 3px;
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 16px;
  outline: 0;
  -webkit-transition: background-color .2s ease-out;
  -o-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  width: 16px;
}

input[type="radio"]+label:before {
  border-radius: 50%;
}

input[type="checkbox"]+label:after,
input[type="radio"]+label:after {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 10px;
  height: 5px;
  content: " ";
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 0;
  transition: 0.3s;
}

input[type="radio"]+label:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background-color: #F74F22;
}

input[type=checkbox]:checked+label:before,
input[type=checkbox]:checked+label.switch-toggle:before {
  background-color: #F74F22;
  border-color: #F74F22;
}

input[type=checkbox]:checked+label:after,
input[type=radio]:checked+label:after {
  opacity: 1;
}

.custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #F74F22;
}

.custom-control label {
    font-weight: 400;
}
.custom-form {
  padding: 40px 40px 10px;
  background-color: #022147;
}
.custom-form .form-control{
  background-color: transparent;
  border: 2px solid #383838;
  color: #fff;
}
.custom-form .form-group i{
  color: #fff;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-left: 2px solid #383838;
}

/*==================
1.4. Buttons
====================*/
.btn-link {
  position: relative;
  color: #022147;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.btn-link.h1{
  font-size: 2.5rem;
  max-width: 230px;
  display: block;
}

.btn-link:hover {
  color: #022147;
  text-decoration: none;
}
.btn-link i{
  transition: .3s;
  margin-left: 5px;
}
.btn-link:hover i{
  transform: translateX(5px);
}

.input-group-text {
  color: #fff;
  padding: 5px 20px;
  font-size: 22px;
  background-color: #F74F22;
  border: 1px solid #F74F22;
}

.sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #F74F22;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
}

.sigma_btn-custom::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  background-color: #d43308;
  width: 10px;
  height: calc(100% - 15px);
  z-index: -1;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_btn-custom:focus::before,
.sigma_btn-custom:hover::before{
  width: calc(100% - 14px);
  opacity: 1;
  visibility: visible;
}

.sigma_btn-custom:hover,
.sigma_btn-custom:focus {
    color: #fff;
    outline: none;
}
.sigma_btn-custom.primary {
    background-color: #F74F22;
}

.sigma_btn-custom.transparent{
  background-color: transparent;
  border: 1px solid transparent;
  color: #022147;
}
.sigma_btn-custom.transparent::before{
  background-color: #ec6a47;
}
.sigma_btn-custom.transparent:hover,
.sigma_btn-custom.transparent:focus{
  color: #fff;
  border: 1px solid #efefef;
}

.sigma_btn-custom.primary::before {
  background-color: #ec6a47;
}

.sigma_btn-custom.dark{
  background-color: #022147;
  color: #fff;
  box-shadow: none;
}
.sigma_btn-custom.dark::before{
  background-color: #05356f;
}
.sigma_btn-custom.light:hover,
.sigma_btn-custom.light:focus,
.sigma_btn-custom.dark:hover,
.sigma_btn-custom.dark:focus{
  color: #fff;
}
.sigma_btn-custom.light {
    background-color: transparent;
    color: #022147;
    box-shadow: none;
    border: 2px solid #efefef;
}

.sigma_btn-custom.light::before {
    background-color: #282828;
}
.sigma_btn-custom.white {
    background-color: #fff;
    color: #022147;
    box-shadow: none;
}
.sigma_btn-custom.white::before {
    background-color: #efefef;
}

.sigma_btn-custom.secondary {
    background-color: #022147;
    color: #fff;
}

.sigma_btn-custom.secondary::before{
    background-color: #05356f;
}

.sigma_btn-custom.btn-sm {
    padding: 12px 26px;
    font-size: 14px;
}
.sigma_btn-custom.btn-pill{
  border-radius: 55px;
}

.sigma_btn-custom i{
  margin-left: 10px;
}

.btn-group .sigma_btn-custom+.sigma_btn-custom {
    padding: 12px 14px;
}

.btn-group .sigma_btn-custom.btn-sm+.sigma_btn-custom.btn-sm {
    padding: 12px 16px;
}

.input-group-prepend,
.input-group-append{
  margin: 0;
  display: flex;
}

.input-group-append .sigma_btn-custom,
.input-group-append .sigma_btn-custom::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceUpDown;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.btn-group.dropup .sigma_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after {
    animation-name: BounceDownUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.sigma_round-button{
  position: relative;
  display: inline-block;
}

.sigma_round-button > span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  color: #022147;
}
.sigma_round-button > span span{
  font-size: 20px;
}
.sigma_round-button:hover{
  color: #777;
}
.sigma_round-button svg{
  width: 160px;
  height: 160px;
  overflow: visible;
}
.sigma_round-button i{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.sigma_round-button .sigma_round-button-stroke{
  fill: none;
  stroke: #efefef;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  color: #efefef;
}
.sigma_round-button .sigma_round-button-circle{
  fill: none;
  stroke: #F74F22;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 20px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 2s;
  color: #F74F22;
  stroke-linecap: round;
}

.sigma_round-button .sigma_round-button-circle.secondary{
  color: #022147;
  stroke: #022147;
}

.sigma_round-button.sm svg{
  width: 100px;
  height: 100px;
}
.sigma_round-button.sm > span{
  font-size: 25px;
}
.sigma_round-button.sm > span span{
  font-size: 14px;
}


/*==================
1.5. Colors & Badges
====================*/
.custom-primary {
  color: #A71B19 !important;
}
.custom-secondary {
  color: #022147;
}

.twitter {
    background-color: rgb(44, 170, 225) !important;
    color: #fff;
}

.twitter:hover {
    background-color: #2caae1;
    color: #fff;
}

.facebook {
    background-color: rgb(59, 87, 157) !important;
    color: #fff;
}

.facebook:hover {
    background-color: #3b579d;
    color: #fff;
}

.google {
    background-color: rgb(220, 74, 56) !important;
    color: #fff;
}

.google:hover {
    background-color: #dc4a38;
    color: #fff;
}

.linkedin {
    background-color: rgb(1, 119, 181) !important;
    color: #fff;
}

.linkedin:hover {
    background-color: #0177b5;
    color: #fff;
}

.pinterest {
    background-color: rgb(204, 33, 39) !important;
    color: #fff;
}

.pinterest:hover {
    background-color: #cc2127;
    color: #fff;
}

.youtube {
    background-color: rgb(229, 45, 39);
    color: #fff;
}

.youtube:hover {
    background-color: #e52d27;
    color: #fff;
}

.github {
    background-color: rgb(51, 51, 51) !important;
    color: #fff;
}

.github:hover {
    background-color: #333333 !important;
    color: #fff;
}

.behance {
    background-color: rgb(23, 105, 255) !important;
    color: #fff;
}

.behance:hover {
    background-color: #1769ff;
    color: #fff;
}

.dribbble {
    background-color: rgb(234, 76, 137) !important;
    color: #fff;
}

.dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
}

.reddit {
    background-color: rgb(255, 69, 0) !important;
    color: #fff;
}

.reddit:hover {
    background-color: #ff4500;
    color: #fff;
}

.light-bg {
    background-color: #f7f7f7;
}
.dark-bg{
  background-color: #022147;
}
.dark-bg-2{
  background-color: #282828;
}
.border-bottom-dark{
  border-bottom: 1px solid #2d2d2d;
}

.primary-bg {
    background-color: #022147;
}
.primary-dark-bg{
  background-color: #ec6a47;
}

.primary-light-bg{
  background-color: #f0fff0;
}

.secondary-bg {
    background-color: #022147;
}

.sigma_img-custom{
  position: relative;
  z-index: 1;
}
.sigma_img-custom img{
  width: 100%;
}


.badge{
  background-color: #f0fff0;
  padding: 6px 11px;
  font-size: 18px;
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
}
.badge.badge-primary{
  color: #F74F22;
  background-color: #f0fff0;
}
.badge.badge-secondary{
  color: #022147;
  background-color: #022147;
}

/*=============================
1.6 Modal, Popups & Dropdowns
==============================*/
.modal-header .close {
    background-color: #020202;
    opacity: 1;
    margin: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-shadow: none;
}

.modal-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-header .close:not(:disabled):not(.disabled):hover {
    background-color: #dd3333;
    opacity: 1;
}

.modal-lg {
    max-width: 1000px;
}

.modal-content {
    border-radius: 0;
    border: 0;
}

.modal-body {
    padding: 40px;
}

.dropdown-menu {
    padding: 10px;
    font-size: 14px;
    color: #777;
    border: 1px solid #efefef;
    box-shadow: 0 4px 24px rgba(0, 0, 0, .1);
}

.dropdown-item {
    padding: 6px 10px;
    font-weight: 600;
    color: #777;
    border-radius: 8px;
}

.dropdown-item i {
    display: inline-block;
    width: 25px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f8f9fa;
    color: #16181b;
}

/*======================
1.7 Tables
========================*/
table{
  margin-bottom: 30px;
}
table th,
table td {
    padding: 15px;
    vertical-align: middle;
    background-color: #fff;
}

table th {
    font-weight: 700;
    color: #022147;
    font-size: 16px;
    border: 1px solid #efefef;
}

table {
    width: 100%;
    margin-bottom: 30px;
}

table td {
    border: 1px solid #efefef;
}

table img {
    width: 40px;
    border-radius: 0;
}

.thead-dark tr th{
  background-color: #022147;
  color: #fff;
  border-color: #32383e;
}

/*======================
1.8 Tabs
========================*/
.tab-content {
    margin-top: 20px;
}

.nav-item {
  margin: 0;
}
.nav-tabs{
  border-bottom: 0;
}
.nav-tabs .nav-link{
  padding: 10px 20px;
  font-weight: 600;
  color: #022147;
  background-color: #f7f7f7;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  color: #F74F22;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover,
.nav-tabs .nav-link{
  border: 0;
  border-radius: 0;
}
.nav-tabs .nav-item + .nav-item{
  margin-left: 5px;
}
.nav-tabs .nav-link i{
  margin-right: 5px;
  color: #A71B19;
}
.sigma_product-additional-info .nav-link.active,
.nav-link.active {
  border-bottom: 2px solid #F74F22;
  font-weight: 600;
  color: #F74F22;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active{
  border-bottom: 2px solid #A71B19;
  font-weight: 600;
}

/*======================
1.9. Social media links
========================*/
.sigma_sm {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_sm li {
    margin: 0;
}

.sigma_sm li a {
    display: inline-block;
}

.sigma_sm li+li {
    margin-left: 10px;
}

.sigma_sm.square li a{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.1);
  color: #fff;
}

.sigma_sm.square.light li a{
  background-color: #f7f7f7;
  color: #022147;
}

.sigma_sm.square.light li a:hover,
.sigma_sm.square li a:hover{
  background-color: #A71B19;
  color: #fff;
}
/*======================
2. Header
========================*/

.sigma_header.header-fw .sigma_header-top > .container,
.sigma_header.header-fw .sigma_header-middle > .container,
.sigma_header.header-fw .sigma_header-bottom > .container{
  width: 100%;
  max-width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
}

@keyframes fadeHeaderInDown {
  0% {
    opacity:0;
    transform:translate3d(0, -100%, 0);
  }
  100% {
    opacity:1;
    transform:translate3d(0, 0, 0);
  }
}

.sigma_header.sticky.header-1 .sigma_header-middle{
  position: fixed;
  top: -100%;
  width: 100%;
  z-index: 990;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.sigma_header.header-1.sticky .sigma_header-middle{
  top: 0;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  animation-name:fadeHeaderInDown;
}
.sigma_header.header-1.sticky:not(.header-light) .sigma_header-middle{
  background-color: #fff;
}

.sigma_header.header-1.sticky .sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover{
  color: #F74F22;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.header-absolute .sigma_header-middle,
.header-absolute .sigma_header-bottom,
.header-absolute .sigma_header-top{
  background-color: transparent;
}
.header-absolute.sigma_header{
  padding: 10px 0;
}

.sigma_header.header-1.sticky .sigma_header-middle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 190;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    background-color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler span{
  background-color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler:hover span{
  background-color: #F74F22;
}
.sigma_header.header-1.sticky .sigma_header-controls-inner li a i,
.sigma_header.header-1.sticky.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}
.sigma_header.header-1.sticky .aside-toggler.desktop-toggler span,
.sigma_header.header-1.sticky.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.sigma_header-top {
  background-color: #022147;
  border-bottom: 1px solid #022147;
  padding: 0;
}

.sigma_header-top .sigma_sm li+li{
  margin-left: 20px;
}

.header-absolute .sigma_header-top{
  border-bottom: 1px solid #efefef;
}

.sigma_header-top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_header-top-links {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_header-top-links li {
    margin: 0;
}
.sigma_header-top-links li a i{
  margin-right: 10px;
}
.sigma_header-top-links > li + li > a{
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #022147;
}

.sigma_header-top .live{
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.sigma_header-top .live i{
  font-size: 10px;
  color: #A71B19;
  transition: 0.3s;
  margin-right: 4px;
  animation: fadeInOut 2s linear infinite;
}
.header-absolute .sigma_header-top-links > li + li > a{
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.sigma_header-top-sm li a,
.sigma_header-top-links a {
    color: #fff;
}

.sigma_header-top-sm li a:hover,
.sigma_header-top-links a:hover {
    color: #F74F22;
}

.sigma_header-top-links a {
    font-weight: 400;
    font-size: 13px;
    padding: 15px 0;
    line-height: normal;
    display: flex;
    align-items: center;
}

.sigma_header-top-links .sigma_account a {
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 10px 0;
}

.sigma_header-top-cta a{
  height: 100%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_header-top-user{
  margin-left: 30px;
  max-width: 40px;
}

.sigma_header-middle {
    background-color: #fff;
}

.sigma_header-middle .navbar {
    padding: 0;
}

.navbar-brand {
    padding: 10px 0;
    width: 190px;
}

/* Logo */
.header-2 .sigma_logo-wrapper{
  position: relative;
  background-color: #fff;
  height: 113px;
  padding: 0 30px;
  margin-bottom: -30px;
  z-index: 3;
  display: flex;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  align-items: center;
}
.header-2 .sigma_logo-wrapper .navbar-brand{
  margin-right: 0;
}

/* Controls */
.sigma_header-controls {
    display: flex;
    align-items: center;
}

.sigma_header-controls-inner {
    display: flex;
    align-items: center;
    margin: 0;
}

.sigma_header-controls-inner li {
    margin: 0;
    position: relative;
}

.sigma_header-controls-inner li + li{
  margin-left: 15px;
}

.sigma_header-controls-inner li a {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
}

.sigma_header-controls-inner li a i {
    font-size: 21px;
    color: #022147;
}

.sigma_header-controls-inner li a:hover {
    background-color: #f7f7f7;
}

.sigma_header-controls-inner li:last-child a {
    margin-right: 0;
}


/* cart and cart dropdown */

.sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-cart > a,
.sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-wishlist > a {
    width: auto;
    height: auto;
    font-weight: 600;
    border-radius: 50%;
    border: 0;
    padding: 23px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-3 .sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-cart > a,
.header-3 .sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-wishlist > a{
  padding: 20px 6px;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-cart > a:hover,
.sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-wishlist > a:hover{
  background-color: transparent;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li > a:hover i,
.sigma_header-controls.style-2 .sigma_header-controls-inner li > a:hover i{
  color: #F74F22;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-cart .number{
  position: absolute;
  right: -7px;
  top: 15px;
  width: 20px;
  height: 20px;
  background-color: #A71B19;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  line-height: 0;
}

.header-3 .sigma_header-controls.style-2 .sigma_header-controls-inner li.sigma_header-cart .number{
  top: 5px;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li a i,
.sigma_header-controls.style-2 .sigma_header-controls-inner li a i {
    font-size: 20px;
    color: #777;
    font-weight: 600;
    line-height: 1;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content {
    margin-left: 15px;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #022147;
}

.sigma_header-controls-inner li.sigma_header-cart .sigma_header-cart-content span+span {
    color: #F74F22;
}

.sigma_header .sigma_header-controls .cart-dropdown{
  display: block;
  left: auto;
  right: 0;
}
.sigma_header .sigma_header-controls .cart-dropdown li + li{
  margin-left: 0;
}

.sigma_header .sigma_header-controls.style-2 .cart-dropdown li a{
  border-bottom: 1px solid #efefef;
  transition: 0.3s;
}

.sigma_header .sigma_header-controls .cart-dropdown li h6{
  margin-bottom: 5px;
}
.sigma_header .sigma_header-controls .cart-dropdown li a:hover h6{
  color: #F74F22;
}



/* Controls Style 2 */
.sigma_header-controls.style-2 .aside-toggler.desktop-toggler,
.sigma_header-controls.style-2 .sigma_header-controls-inner li a{
  width: auto;
  height: auto;
  border-radius: 0;
  border: 0;
}
.sigma_header-controls.style-2 .aside-toggler span{
  margin-left: auto;
}

.sigma_header-controls.style-2 .sigma_header-controls-inner li a:hover{
  background-color: transparent;
}

.sigma_header-inner ul.navbar-nav > li > a{
  padding-left: 10px;
  padding-right: 10px;
} 

/* Header Bottom */
.sigma_header-bottom {
    background-color: #022147;
}

.sigma_header-bottom-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_header-bottom-inner .navbar-nav,
.sigma_header-middle .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.sigma_header-bottom-inner .navbar-nav .menu-item,
.sigma_header-middle .navbar-nav .menu-item {
    margin: 0;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
  position: relative;
  padding: 20px;
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 600;
}

.sigma_header-middle .navbar>.navbar-nav>.menu-item>a {
    color: #022147;
    padding: 30px 15px;
    text-transform: uppercase;
    font-size: 13px;
}
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a::before{
  content: '';
  width: 100%;
  height: 0;
  transition: .3s;
  background-color: #fff;
}
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover::before{
  height: 100%;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item>a:hover,
.sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover {
    color: #A71B19;
}

.sigma_header-bottom-inner>.navbar-nav>.menu-item:first-child>a,
.sigma_header-middle .navbar>.navbar-nav>.menu-item:first-child>a {
    padding-left: 0;
}

.sigma_header-bottom-inner .navbar-nav .menu-item-has-children,
.sigma_header-middle .navbar-nav .menu-item-has-children {
    position: relative;
}

.sigma_header .sub-menu,
.sigma_flex-menu .sub-menu,
.sigma_header-controls .cart-dropdown {
    position: absolute;
    top: 105%;
    left: 0;
    border-radius: 0;
    width: 100%;
    background-color: #fff;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
    min-width: 230px;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.sigma_flex-menu>ul {
    display: flex;
    margin: 0;
    align-items: center;
}

.sigma_flex-menu ul li {
    margin: 0;
}

.sigma_flex-menu>ul>li>a {
    padding: 0 10px 20px 10px;
    display: block;
    font-weight: 600;
    color: #022147;
}

.sigma_flex-menu>ul>li>a:hover {
    color: #F74F22;
}

.sigma_flex-menu>ul>li:first-child>a {
    padding-left: 0;
}

.sigma_header .sub-menu.sub-menu-left,
.sigma_flex-menu .sub-menu.sub-menu-left {
    left: auto;
    right: 0;
}

.sigma_header-middle .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children:hover>.sub-menu,
.sigma_search-adv-cats:hover .sub-menu,
.sigma_header-top-links .menu-item-has-children:hover .sub-menu,
.sigma_flex-menu .menu-item-has-children:hover .sub-menu,
.sigma_header-controls > ul li:hover .cart-dropdown {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.sigma_header .sub-menu a,
.sigma_flex-menu .sub-menu a,
.sigma_header-controls .cart-dropdown a,
.sigma_header-controls .cart-dropdown p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    color: #777;
    font-weight: 400;
}

.sigma_header .sub-menu a:hover,
.sigma_flex-menu .sub-menu a:hover {
  color: #F74F22;
  transform: translateX(5px);
}

.sigma_header-middle .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_header-bottom-inner .navbar-nav .menu-item-has-children .sub-menu .sub-menu,
.sigma_search-adv-cats .sub-menu .sub-menu,
.sigma_header-top-links .menu-item-has-children .sub-menu .sub-menu,
.sigma_flex-menu .menu-item-has-children .sub-menu .sub-menu{
    top: 0;
    left: 100%;
}

.sigma_header .sub-menu .menu-item-has-children>a>span,
.sigma_flex-menu .sub-menu .menu-item-has-children>a>span {
    transform: rotate(-90deg);
}

.sigma_search-adv form {
    display: flex;
}

.sigma_search-adv-cats {
    position: relative;
    background-color: #f7f7f7;
    border-radius: 25px 0 0 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sigma_search-adv-cats label {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #777;
    font-weight: 400;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    margin: 0;
    transition: .3s;
}

.sigma_header .sub-menu label:hover {
    background-color: #f7f7f7;
}

.sigma_search-adv-cats label input {
    position: absolute;
    opacity: 0;
    visibility: hidden
}

.sigma_search-adv-cats label input+i {
    color: #30ca71;
    transition: .3s;
    visibility: hidden;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    font-size: 12px;
}

.sigma_search-adv-cats label input:checked+i {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.sigma_search-adv-cats>span {
    color: #022147;
    font-size: 13px;
    font-weight: 600;
}

.sidebar-widget .sigma_search-adv-input {
    width: 100%;
}

.sidebar-widget .custom-control-label {
    font-weight: 400;
}

.sigma_search-adv-input {
    position: relative;
}
.sigma_search-adv-input .form-control{
  background-color: #f53400;
  border: 0;
  color: #fff;
  box-shadow: none;
}
.sidebar-widget .sigma_search-adv-input .form-control{
  background-color: #f7f7f7;
  color: #6e6e6e;
}
.sidebar-widget .sigma_search-adv-input .form-control::placeholder{
  color: #a5a5a5;
}
.sigma_search-adv-input .form-control::placeholder{
  color: #fff;
}
.sigma_search-adv-input button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 0;
    background-color: #ec6a47;
    color: #fff;
    transition: .3s;
}

.sigma_search-adv-input button:hover {
    color: #fff;
}

.sigma_search-adv-cats .sub-menu li,
.sigma_search-adv-cats .sub-menu {
    margin: 0;
}

.sigma_search-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.8);
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.sigma_search-form-wrapper.open {
  opacity: 1;
  visibility: visible;
}
.sigma_search-form-wrapper .close-btn {
  position: absolute;
  top: 60px;
  right: 60px;
}
.sigma_search-form-wrapper .close-btn span{
  height: 40px;
}
.sigma_search-form-wrapper form {
  position: relative;
  width: 70%;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transform: scale(.5);
  -ms-transform: scale(.5);
  transform: scale(.5);
  opacity: 0;
  visibility: hidden;
}
.sigma_search-form-wrapper.open form {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.sigma_search-form-wrapper form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  padding: 20px 0;
  font-size: 40px;
  width: 100%;
  outline: none;
  color: #fff;
}
.sigma_search-form-wrapper form input::placeholder{
  color: #fff;
}
.sigma_search-form-wrapper .sigma_search-btn {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 30px;
  outline: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
  padding: 0;
}
.sigma_header-custom-link{
  display: flex;
  align-items: center;
}
.sigma_header-custom-link i{
  font-size: 20px;
  color: #F74F22;
  margin-right: 10px;
}

/*header-light*/
.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  color: #fff;
}
.header-light.sticky .sigma_header-middle .navbar>.navbar-nav>.menu-item>a{
  color: #022147;
}

.header-light  .sigma_header-custom-link{
  color: #fff;
}

.header-light .sigma_header-controls-inner li a i{
  color: #fff;
}

.header-light .aside-toggler span,
.header-light .aside-toggler.desktop-toggler span{
  background-color: #fff;
}

.header-light .sigma_header-middle .navbar>.navbar-nav>.menu-item>a:hover,
.header-light .sigma_header-top-sm li:hover a{
  color: #F74F22;
}

/* Mega Menu */
.navbar-nav .menu-item.mega-menu-wrapper {
    position: static;
}
.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li > img{
  position: absolute;
  width: 410px;
  right: 20px;
  bottom: 0;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu {
    width: 100%;
}

.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li {
    margin: 0;
    padding: 20px;
}

.mega-menu-promotion-wrapper {
    margin-top: 30px;
}

.navbar-nav .mega-menu-promotion {
    text-align: center;
}

.navbar-nav .mega-menu-promotion>a {
    display: block;
    padding: 0;
}

.navbar-nav .mega-menu-promotion img {
    width: 150px;
    margin-bottom: 5px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.navbar-nav .mega-menu-promotion:hover img {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
}

.mega-menu-promotion-text h4 {
    margin-bottom: 5px;
    font-size: 18px;
}

.mega-menu-promotion-text span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #848486;
}

.navbar-nav .mega-menu-promotion-text h4 a {
    display: block;
    padding: 0;
    color: #022147;
}

.navbar-nav .mega-menu-promotion-text h4 a:hover {
    color: #ED4E53;
}

.navbar-nav .mega-menu-item h6 {
    margin-bottom: 10px;
    font-size: 24px;
}

.navbar-nav .mega-menu-item p {
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a {
    display: block;
    font-weight: 400;
    padding: 10px 0;
}

.navbar-nav .mega-menu-item a:last-child {
    border-bottom: 0;
}

.navbar-nav .mega-menu-item a:hover {
    background-color: transparent;
    color: #F74F22;
}

.navbar-nav .mega-menu-item .sigma_btn-custom {
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
}

.navbar-nav .mega-menu-item .sigma_btn-custom:hover {
    color: #fff;
    background-color: #ec6a47;
}

.navbar-nav .mega-menu-item a.coming-soon {
    color: #f5f5f5;
    cursor: default;
}

.navbar-nav .mega-menu-item a.coming-soon span {
    margin-left: 5px;
    font-size: 12px;
}

/*sigma_header-controls*/
.sigma_header-controls {
    display: flex;
    align-items: center;
}

.sigma_header-controls ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.sigma_header-controls ul>li {
    margin-top: 0;
}

.sigma_header-controls ul>li>a {
  position: relative;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.sigma_notification-count {
  background: #F74F22;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  line-height: normal;
  position: absolute;
  top: -10px;
  right: -10px;
}

.sigma_header-contact{
  display: flex;
  align-items: center;
}
.sigma_header-contact i{
  font-size: 40px;
  line-height: 1;
  margin-right: 10px;
}
.sigma_header-contact span{
  font-size: 12px;
  color: #777;
  text-transform: uppercase;
  font-weight: 600;
}
.sigma_header-contact h6{
  margin: 0;
  line-height: 1;
}

/* Mobile sidenav */
.aside-toggler {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    transition: .3s;
}

.aside-toggler.desktop-toggler {
    display: flex;
    height: 58px;
    width: 58px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: .3s;
    border-radius: 8px;
    border: 1px solid #efefef;
}

.aside-toggler.desktop-toggler span {
    background-color: #A71B19;
}

.aside-toggler.desktop-toggler:hover {
    background-color: transparent;
}

.aside-toggler span {
    display: block;
    margin-bottom: 4px;
    width: 20px;
    height: 1px;
    border-radius: 0;
    background-color: #A71B19;
    transition: .3s;
    transform-origin: right;
}
.aside-toggler span:nth-child(even){
  width: 30px;
}

.sigma_header-controls.style-2 .aside-toggler.style-2,
.aside-toggler.style-2{
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 4px;
}
.sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
.aside-toggler.style-2.desktop-toggler{
  display: grid;
}

.aside-toggler.style-2 span{
  width: 8px;
  height: 8px;
  margin: 0;
}
.aside-toggler.style-2 span:nth-child(2){
  grid-row: 1;
  grid-column: 2;
}
.aside-toggler.style-2 span:nth-child(3){
  grid-row: 1;
  grid-column: 3;
}
.aside-toggler.style-2 span:nth-child(4){
  grid-row: 3;
  grid-column: 1;
}
.aside-toggler.style-2 span:nth-child(5){
  grid-row: 3;
  grid-column: 3;
}
.aside-toggler.style-2 span:nth-child(6){
  grid-row: 3;
  grid-column: 1;
}
.aside-toggler.style-2 span:nth-child(7){
  grid-row: 3;
  grid-column: 2;
}
.aside-toggler.style-2 span:nth-child(8){
  grid-row: 2;
  grid-column: 3;
}

.aside-toggler.style-2:hover span:nth-child(2),
.aside-toggler.style-2:hover span:nth-child(7),
.aside-toggler.style-2:hover span:nth-child(9),
.aside-toggler.style-2:hover span:nth-child(8){
  opacity: .4;
}


.aside-toggler.style-2:hover span{
  width: 8px;
  height: 8px;
  background-color: #F74F22;
}

.sigma_aside {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  transition: .5s cubic-bezier(.77,0,.175,1);
  overflow: auto;
}

.sigma_aside-overlay.aside-trigger-left,
.sigma_aside.sigma_aside-left {
    display: none;
}

.sigma_aside.sigma_aside-right {
    left: auto;
    right: -400px;
    padding: 30px;
    width: 400px;
}

.sigma_aside.sigma_aside-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: -1;
    background-position: 0 130%;
    background-repeat: no-repeat;

}

.sigma_aside.sigma_aside-right .sidebar {
    display: none;
}

.sigma_aside.open {
    left: 0;
}

.sigma_aside.sigma_aside-right.open {
    left: auto;
    right: 0;
}

.sigma_aside.open+.sigma_aside-overlay {
    opacity: 1;
    visibility: visible;
}

.sigma_aside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, .7);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.sigma_aside ul {
    margin: 0;
}

.sigma_aside ul .menu-item {
    margin: 0;
}

.sigma_aside ul .menu-item a {
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #022147;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_aside ul .menu-item a:hover,
.sigma_aside ul .menu-item a.active {
    color: #F74F22;
}

.navbar-nav .menu-item-has-children>a::after,
.sigma_aside ul .menu-item.menu-item-has-children>a::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f067";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    margin-left: auto;
    font-size: 10px;
}
.navbar-nav .menu-item-has-children>a::after{
  margin-left: 10px;
}
.navbar-nav .sub-menu .menu-item-has-children>a::after{
  content: "\f054";
}

.sigma_aside ul .menu-item .sub-menu {
    display: none;
    background-color: #f7f7f7;
}

.sigma_aside .navbar-brand {
    padding: 10px 15px;
    display: block;
    width: 200px;
    margin: 0;
}

.sigma_aside.sigma_aside-right .sidebar-widget .widget-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
    font-size: 22px;
}

.sigma_aside.sigma_aside-right .sidebar-widget.widget-categories-icons+.sidebar-widget {
    margin-top: 0;
}
.sigma_aside.sigma_aside-right .sidebar-widget{
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

/* -- Aside trigger Hover sequence -- */
.aside-toggler:hover span {
  width: 30px;
}

/* Header Cart */
.sigma_cart-sidebar-wrapper.sigma_aside{
  padding: 0;
  width: 60vw;
  max-width: 1200px;
  right: -1200px;
}
.sigma_cart-sidebar{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sigma_cart-sidebar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px;
  text-transform: uppercase;
}
.sigma_cart-sidebar-body {
  position: relative;
  padding: 0 40px;
  height: calc(100% - 245px);
  overflow-y: auto;
}

.sigma_cart-sidebar-header .close-btn span {
  height: 30px;
  opacity: 1;
}
.sigma_cart-sidebar-header h4{
  margin: 0;
  font-size: 30px;
}
.sigma_cart-sidebar-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  padding: 40px;
  margin-top: auto;
}
.sigma_cart-sidebar-footer h4 {
  margin: 0;
  font-size: 20px;
}
.sigma_cart-sidebar-footer h4 span {
  font-size: 28px;
  margin-left: 10px;
}

.cart-open .sigma_cart-sidebar-wrapper.sigma_aside{
  right: 0;
}
.cart-open .sigma_aside-overlay.sigma_cart-sidebar-overlay{
  opacity: 1;
  visibility: visible;
}

.sigma_cart-sidebar-item{
  position: relative;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px dashed #9F9E9E;
}
.sigma_cart-sidebar-item:last-child{
  border-bottom: 0;
}
.sigma_cart-sidebar-item-body{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 270px;
  display: -ms-flexbox;
  display: flex;
}
.sigma_cart-sidebar-item-body img{
  margin-right: 15px;
  width: 90px;
}
.sigma_cart-sidebar-item-body-inner{
  flex: 1;
}
.sigma_cart-sidebar-item-body-inner h5{
  margin-bottom: 10px;
}

/* 2.1. Header Style 2 */
.header-2 .sigma_header-middle .navbar{
  align-items: unset;
}

.header-2 .sigma_header-controls.style-2{
  padding: 0 30px;
  border-right: 1px solid #efefef;
}

.header-2 .sigma_header-controls.style-1 a{
  padding: 0 30px;
  border-left: 1px solid #efefef;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
}

.header-2 .sigma_header-controls .sigma_btn-custom{
  margin-left: 30px;
}

.header-2 .sigma_header-middle .navbar-nav{
  margin-left: 50px;
  margin-right: auto;
}
.header-2 .sigma_header-middle .sigma_header-button{
  margin-right: 50px;
  margin-left: auto;
}

/* 2.2. Header Style 3 */

.header-absolute.sigma_header.header-3{
  padding: 15px;
}
.sigma_header.header-3 .sigma_header-middle{
  background-color: #fff;
}

.header-3 .sigma_header-middle .navbar{
  align-items: unset;
}

.header-3 .sigma_logo-wrapper{
  border-right: 1px solid #efefef;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-3 .sigma_logo-wrapper .navbar-brand{
  margin: 0;
}

.header-3 .sigma_header-controls{
  padding: 20px;
  border-left: 1px solid #efefef;
}

.sigma_header-inner{
  flex: 1;
}
.sigma_header-inner ul > li > a{
  color: #022147;
  padding: 20px 15px;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  display: block;
  font-weight: 600;
}

.sigma_header-inner .navbar-nav,
.sigma_header-inner .sigma_header-top{
  padding: 0 20px;
}

/* 2.1. Header Style 4 */
.sigma_header.header-4.header-absolute{
  padding: 0;
}
.sigma_header.header-4.header-absolute .sigma_header-top{
  border-bottom: 0;
}

.sigma_header.header-4 .sigma_header-top-inner{
  align-items: center;
  justify-content: space-between;
}

.sigma_header.header-4 .sigma_header-middle .navbar{
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(53,82,99,0.09);
}

.sigma_header.header-4 .sigma_header-button{
  margin-left: auto;
  margin-right: 30px;
}

.sigma_header.header-4 .sigma_header-middle .navbar-nav{
  margin-left: 20px;
}
.sigma_header.header-4 .sigma_sm li a{
  color: #777;
}
.sigma_header.header-4 .sigma_sm li a:hover{
  color: #F74F22;
}

.sigma_header.header-4 .sigma_header-top-links a{
  color: #022147;
  font-weight: 600;
}
.sigma_header.header-4 .sigma_header-top-links a:hover{
  color: #A71B19;
}

/*======================
3. Footer
========================*/

.sigma_footer {
  position: relative;
  background-color: #f7f7f7;
  padding: 90px 0 0;
  overflow: hidden;
  z-index: 1;
  font-weight: 400;
}

.sigma_footer p {
    margin-bottom: 10px;
    color: #515151;
    font-weight: 400;
}

.sigma_footer-top {
  margin-bottom: 50px;
}
.sigma_footer-top .container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sigma_footer-middle a,
.sigma_footer-middle i {
    color: #515151;
}

.sigma_footer-middle a:hover {
    color: #F74F22;
}

.sigma_footer-middle a.sigma_btn-custom,
.sigma_footer-middle a.sigma_btn-custom:hover {
    color: #fff;
}
.sigma_footer-bottom{
  border-top: 1px solid #efefef;
  padding: 30px 0;
  position: relative;
}

.sigma_footer-bottom .container-fluid{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_footer-bottom .sigma_footer-logo{
  background-color: #fff;
  height: 120px;
  padding: 0 40px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  align-items: center;
}

.sigma_footer-bottom .sigma_footer-copyright p {
    margin: 0;
    text-transform: uppercase;
}

.sigma_footer-bottom .sigma_footer-copyright>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #022147;
    font-size: 14px;
    font-weight: 400;
}

.sigma_footer-bottom .sigma_footer-copyright>a:hover {
    color: #F74F22;
}

.footer-widget {
    padding-bottom: 40px;
}
.space {
  max-width: 175px;  
}
.spacex {
  margin-right: 50px;
}
.footer-widget .sigma_sm{
  margin-top: 25px;
}
.footer-widget .sigma_sm li{
  margin: 0;
}
.footer-widget .sigma_sm li + li{
  margin-left: 20px;
}
.footer-widget .sigma_sm li a{
  transform: translateX(0);
  font-size: 18px;
}
.footer-widget .sigma_sm li a i{
  color: #f5f5f5;
  transition: .3s;
}
.footer-widget .sigma_sm li a:hover i{
  color: #fff;
}
.footer-widget .sigma_sm li a::before{
  display: none;
}

.footer-widget .widget-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}

.footer-widget ul {
    margin: 0;
}
.footer-widget ul li{
  margin: 0;
}
.footer-widget ul li+li {
    margin: 15px 0 0;
}

.footer-widget ul li a {
  position: relative;
  display: inline-block;
  font-weight: 400;
}
.footer-widget h5 a{
  position: relative;
}

.footer-widget ul li a i {
    font-weight: 400;
}

.footer-widget ul.social-media li {
    display: inline-block;
    margin-top: 0;
}

.footer-widget ul.social-media li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    color: #fff;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer-widget ul.social-media li a::before {
    display: none;
}

.footer-widget ul.social-media li a i {
    color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.footer-widget ul.social-media li a:hover i {
    color: #fff;
}

.sigma_footer-offer {
    margin-top: 15px;
}

.sigma_footer-offer p {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
}

.sigma_footer-offer .btn-custom,
.sigma_footer-offer .btn-custom:hover {
    color: #fff;
}

.sigma_instagram {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.sigma_ig-item {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 2px 5px;
}
.sigma_ig-item::before{
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 3;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  color: #F74F22;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_ig-item:hover::before{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_ig-item:hover img {
  transform: scale(1.1);
  filter: blur(1px);
}

.sigma_ig-item img {
  transition: .3s;
  width: 100%;
}

/* Footer 2 */
.sigma_footer.footer-2{
  padding: 0;
  background-color: #fff;
}
.sigma_footer.footer-2 .sigma_footer-top{
  margin-bottom: 0;
}
.sigma_footer.footer-2 .sigma_footer-top-item{
  padding: 25px 15px;
  flex: 1;
  display: flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-top-item + .sigma_footer-top-item{
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a{
  color: #fff;
  opacity: .8;
  font-size: 15px;
  display: block;
}
.sigma_footer.footer-2 .sigma_footer-sm{
  justify-content: flex-end;
}
.sigma_footer.footer-2 .sigma_footer-top.secondary-bg .sigma_sm li a:hover{
  color: #F74F22;
  opacity: 1;
}


.sigma_footer.footer-2 .sigma_footer-newsletter form{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form::before{
  content: "\f0e0";
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  color: rgba(255,255,255,.8);
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form input{
  background: no-repeat;
  border: 0;
  color: #fff;
  padding: 9px 25px 9px 10px;
  flex: 1;
  outline: none;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button:focus,
.sigma_footer.footer-2 .sigma_footer-newsletter form input:focus{
  outline: none;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form input::placeholder{
  color: rgba(255,255,255,.8);
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button{
  background: none;
  padding: 0;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 14px;
  float: right;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button i{
  margin-left: 10px;
}
.sigma_footer.footer-2 .sigma_footer-newsletter form button::before{
  background-color: #fff;
}
.sigma_footer.footer-2 .sigma_footer-middle{
  padding-top: 80px;
}

.sigma_footer .sigma_footer-bottom ul li a{
  font-weight: 400;
}

/* Dark Footer */
.sigma_footer.sigma_footer-dark {
  background-color: #042248;
  color: #777;
}
.sigma_footer.sigma_footer-dark.primary-bg,
.sigma_footer.primary-bg{
  background-color: #F74F22;
}
.sigma_footer.sigma_footer-dark .sigma_footer-bottom{
  border-top: 1px solid #154075;
}

.sigma_footer.sigma_footer-dark .footer-widget ul li a::before,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom ul li a:hover,
.sigma_footer.sigma_footer-dark .footer-widget ul li a:hover,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom .sigma_footer-copyright>a,
.sigma_footer.sigma_footer-dark .footer-widget .widget-title {
    color: #fff;
}

.sigma_footer.sigma_footer-dark p,
.sigma_footer.sigma_footer-dark .footer-widget ul li a {
    color: #777;
}

/*======================
5. Subheader
========================*/

.sigma_subheader {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sigma_subheader-inner {
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-absolute + .sigma_subheader .sigma_subheader-inner{
  padding: 270px 0 150px;
}
.header-absolute + .sigma_subheader.subheader-lg .sigma_subheader-inner{
  padding: 230px 0 340px;
}

.sigma_subheader h1 {
    font-size: 60px;
    margin-bottom: 0;
    font-weight: 700;
    color: #fff;
}
.sigma_subheader .blockquote{
  max-width: 500px;
  left: 10px;
}

.sigma_subheader .breadcrumb {
    padding: 20px;
    margin: 0;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.sigma_subheader .breadcrumb li {
    margin: 0;
    color: #f1f1f1;
}
.sigma_subheader .breadcrumb-item+.breadcrumb-item{
  padding-left: 15px;
}

.sigma_subheader .breadcrumb li a {
  position: relative;
  color: #777;
  font-weight: 700;
  font-size: 14px;
}
.sigma_subheader .breadcrumb li a::before{
  background-color: #fff;
}

.sigma_subheader .breadcrumb li a:hover {
    color: #777;
}

.sigma_subheader .breadcrumb .breadcrumb-item.active {
    color: #A71B19;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
}

.sigma_subheader .breadcrumb-item+.breadcrumb-item::before {
    color: #777;
    content: "|";
    margin-left: auto;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    padding-right: 15px;
}


.sigma_subheader .sigma_sm{
  justify-content: flex-end;
}
.sigma_subheader .sigma_sm li a{
  color: #777;
}
.sigma_subheader .sigma_sm li a:hover{
  color: #fff;
}

.vertical-toggler{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.vertical-toggler span{
  display: block;
  height: 20px;
  width: 3px;
  margin: 0 2px;
  transition: .3s;
  background-color: #F74F22;
}
.vertical-toggler span:nth-child(odd){
  height: 30px;
}
.vertical-toggler:hover span{
  height: 30px;
}
.vertical-toggler:hover span:nth-child(odd){
  height: 20px;
}

.sigma_subheader-extras{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(255,255,255,.9);
  opacity: 0;
  visibility: hidden;
  padding: 30px;
  transition: .5s cubic-bezier(.77,0,.175,1);
  z-index: 49;
}
.sigma_subheader-extras .sigma_map{
  height: 300px;
}
.sigma_subheader-extras.open{
  height: 50%;
  opacity: 1;
  visibility: visible;
}
.sigma_subheader-extras .close-btn{
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid #272727;
}
.sigma_subheader-extras .close-btn span{
  height: 25px;
}

.sigma_subheader-extras .sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 20px;
  padding-top: 0;
  border-top: 0;
}

/*======================
6. Components
========================*/

/* 6.1. Blog Posts */
.sigma_post {
    position: relative;
    margin-bottom: 30px;
}

.sigma_post .sigma_post-thumb,
.gallery-thumb {
    position: relative;
    display: block;
    overflow: hidden;
}
.entry-content .gallery-thumb{
  margin-bottom: 20px;
}
.entry-content .gallery-thumb img{
  margin: 0;
}
.gallery-thumb img,
.sigma_post .sigma_post-thumb img{
  width: 100%;
  transition: .3s;
}

.sigma_post .sigma_post-thumb:hover img,
.gallery-thumb:hover img{
  transform: scale(1.2);
}

.sigma_post .event-date-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 140px;
  background-color: #022147;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sigma_post .event-date-wrapper span{
  display: block;
  line-height: 1;
  margin-bottom: 4px;
  color: #F74F22;
  font-size: 40px;
}

.sigma_post.event-list .sigma_post-body .sigma_post-meta{
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  margin-top: 25px;
}

.sigma_post.event-list .sigma_post-body .sigma_post-meta a{
  font-size: 14px;
}

.sigma_post.event-list .sigma_post-body .sigma_post-meta a + a{
  margin-left: 0;
  margin-top: 10px;
}

.entry-content .sigma_event-timer{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(33, 33, 33, 0.8);
  width: 100%;
  display: flex;
  text-align: center;
}

.entry-content .sigma_event-timer .sigma_event-date{
  width: 14%;
  padding: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.entry-content .sigma_event-timer .sigma_event-date span{
  display: block;
  color: #F74F22;
  font-size: 30px;
  margin-bottom: -6px;
}

.entry-content .sigma_event-timer .sigma_countdown-timer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: 0;
  width: 84%;
}

.entry-content .sigma_event-timer .sigma_countdown-timer li{
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.entry-content .sigma_event-timer .sigma_countdown-timer h5{
  color: #fff;
  margin-bottom: 0;
}

.entry-content .sigma_event-timer .sigma_countdown-timer li span{
  color: #fff;
  font-size: 16px;
  margin-top: 5px;
}

.gallery-thumb{
  z-index: 1;
}

.gallery-thumb .caption{
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 11px;
  letter-spacing: 1.4px;
  z-index: 3;
}
.gallery-thumb.lg .caption{
  font-size: 18px;
}

.sigma_post .sigma_post-body {
    padding: 30px;
    border: 1px solid #efefef;
    position: relative;
    background-color: #fff;
    z-index: 1;
}

.sigma_post-meta{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.sigma_post-meta i{
  margin-right: 5px;
  color: #F74F22;
}

.sigma_post-meta a,
.sigma_post-date{
  font-size: 13px;
  color: #022147;
  font-weight: 600;
}
.sigma_post-meta a:hover{
  color: #F74F22;
}

.sigma_post-categories{
  margin-right: 20px;
}

.sigma_post-categories a {
    background-color: #efefef;
    display: inline-block;
    padding: 4px 30px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.7;
    border-radius: 0;
    margin: 0 3px 3px 0;
    background-color: #F74F22;
}

.sigma_post-categories a:hover {
    background-color: #ec6a47;
    color: #fff;
}

.sigma_post-date span{
  color: #022147;
  transition: .3s;
  font-size: 18px;
  font-weight: 800;
}
.sigma_post-date:hover span{
  color: #ec6a47;
}
.sigma_post-date i{
  margin-right: 5px;
}

.sigma_post .sigma_post-body h5 {
    margin: 0 0 15px;
    word-break: break-word;
    font-size: 18px;
    margin-bottom: 0;
}
.sigma_post .sigma_post-body h5 + p{
  margin: 20px 0 0;
}

.sigma_post .sigma_post-body h5 a {
    color: #022147;
}

.sigma_post .sigma_post-body h5 a:hover,
.sigma_post .sigma_post-body .sigma_post-desc>span a:hover {
    color: #ec6a47;
}

.sigma_post .sigma_post-body .sigma_post-desc>span {
    display: block;
    color: #022147;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 10px;
}

.sigma_post .sigma_post-body .sigma_post-desc>span a {
    color: #F74F22;
}

.sigma_post .sigma_post-body .sigma_post-desc p {
    margin-bottom: 20px;
}

/* 6.1.1. Blog Posts list */
.sigma_post.sigma_post-list .sigma_post-body{
  padding: 40px;
}
.sigma_post.sigma_post-list .sigma_post-single-author{
  margin-top: 0;
}

.sigma_post.sigma_post-list .sigma_post-body h5{
  font-size: 32px;
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list .sigma_post-meta{
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list .sigma_post-meta a + a{
  margin-left: 20px;
}
.sigma_post.sigma_post-list .sigma_post-footer{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Formats */
.sigma_post.post-format-video .sigma_post-thumb .sigma_video-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sigma_post.post-format-audio .embed-responsive-16by9::before{
  padding-top: 16.25%;
}
.sigma_post.post-format-quote{
  background-color: #F74F22;
}

.sigma_post.post-format-quote h5 a,
.sigma_post.post-format-quote *{
  color: #fff;
}

.sigma_post.post-format-quote .sigma_post-meta a:hover,
.sigma_post.post-format-quote h5 a:hover,
.sigma_post.post-format-quote h5 a:hover{
  color: #022147;
}
.sigma_post.post-format-quote .sigma_post-body{
  padding: 40px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
}
.sigma_post.post-format-quote .sigma_post-body > i{
  margin-right: 20px;
  font-size: 70px;
  line-height: 1;
}
.sigma_post.post-format-quote .sigma_post-meta{
  margin: 0;
}

.sigma_post.post-format-image .sigma_post-thumb{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sigma_post.post-format-image .sigma_post-body{
  background-color: transparent;
}
.sigma_post.post-format-image .sigma_post-body p{
  margin: 0;
}
.sigma_post.post-format-image .sigma_post-body::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(255,255,255,.9);
}


/* Style 2 */
.sigma_post.style-2 .sigma_post-body{
  border: 0;
  border-top: 3px solid #A71B19;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  width: calc( 100% - 40px );
  margin-left: 20px;
  margin-top: -45px;
}

/* 6.1.2. Blog Post Details */

.sigma_post-single .section{
  padding: 0;
}
.sigma_post-single .section + .section{
  padding-top: 50px;
}
.post-detail-wrapper{
  padding: 40px;
  border: 2px solid #efefef;
}

.post-detail-wrapper .sigma_post-meta a + a{
  margin-left: 20px;
}
.post-detail-wrapper .sigma_post-meta{
  margin-bottom: 20px;
}

.comments-list ul {
    margin: 0;
}
.comment-form{
  padding: 40px;
  background-color: #f7f7f7;
}
.comments-list .comment-item {
  position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 0;
}
.comments-list .comment-item + .comment-item{
  border-top: 1px solid #efefef;
  padding-top: 30px;
}

.comments-list .comment-item:last-child {
    margin-bottom: 0;
}

.comments-list .comment-item img {
    width: 100px;
    margin-right: 20px;
    border-radius: 50%;
}

.comments-list .comment-item .comment-body {
    position: relative;
    flex: 1;
}

.comments-list .comment-item h5 {
    margin-bottom: 15px;
    font-size: 18px;
}

.comments-list .comment-item span {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 21px;
    color: #F74F22;
    font-weight: 600;
}

.comments-list .comment-item .btn-link{
  padding: 10px 25px;
  border: 2px solid #efefef;
  border-radius: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.comments-list .comment-item p {
    margin-bottom: 15px;
}

.reply-link {
    position: relative;
    color: #022147;
    font-weight: 600;
}

.sigma_post-single-thumb {
  position: relative;
    margin-bottom: 30px;
}
.sigma_post-single-thumb img{
  margin: 0;
}

.sigma_post-single-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sigma_post-single-author img {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
}
.sigma_post-single-author-content{
  display: flex;
  align-items: center;
}
.sigma_post-single-author-content p {
    font-weight: 600;
    color: #022147;
    margin-bottom: 0;
    margin-left: 5px;
}

.sigma_post-single-author-content span {
    font-size: 13px;
}

.sigma_author-about{
  display: flex;
  align-items: center;
  padding: 30px;
  border: 2px solid #efefef;
}
.sigma_author-about img{
  width: 150px;
  margin-right: 30px;
}
.sigma_author-about .sigma_author-about-content{
  flex: 1;
}
.sigma_author-about .sigma_author-about-content span{
  color: #F74F22;
  font-weight: 600;
  text-transform: uppercase;
}
.sigma_author-about .sigma_author-about-content p{
  margin: 0;
}

.sigma_post-single-meta {
    display: flex;
    align-items: center;
    padding: 0 0 50px;
    margin-top: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sigma_post-single-meta-item {
    flex: 1;
}

.sigma_post-single-meta-item h6{
  margin-bottom: 5px;
  font-size: 16px;
}
.sigma_post-share .sigma_sm li{
  margin-top: 6px;
}
.sigma_post-share .sigma_sm li+li {
    margin-left: 15px;
}
.sigma_post-single-meta-item.sigma_post-share{
  text-align: right;
}
.sigma_post-single-meta-item.sigma_post-share .sigma_sm{
  justify-content: flex-end;
}

.sigma_post-single-meta-item .sigma_product-controls{
  position: relative;
  transform: translate(0,0);
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  justify-content: flex-end;
}
.sigma_post-single-meta-item .sigma_product-controls a{
  background-color: #f7f7f7;
}
.sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share{
  text-align: left;
}

.sigma_product-single-content .sigma_post-single-meta-item.sigma_post-share .sigma_sm{
  justify-content: flex-start;
}

.sigma_post-share .sigma_sm li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_post-share .sigma_sm li a i {
    color: #777;
}

.sigma_single-pagination {
    margin: 30px 0;
}

.sigma_single-pagination-item+.sigma_single-pagination-item {
    margin-top: 10px;
}

.sigma_single-pagination-thumb {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 0;
    margin-right: 10px;
}

.sigma_single-pagination a {
    display: flex;
    align-items: center;
}

.sigma_single-pagination a h6 {
    margin: 0;
    font-size: 14px;
}

.sigma_single-pagination a img {
    max-width: 40px;
}

.sigma_single-pagination-prev,
.sigma_single-pagination-next {
    padding: 10px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    transition: .3s;
}

.sigma_single-pagination-prev:hover,
.sigma_single-pagination-next:hover {
    background-color: #f7f7f7;
}

.sigma_single-pagination i {
    color: #777;
    margin-left: auto;
    margin-right: 20px;
    transition: .3s;
}

.sigma_single-pagination-prev:hover i {
    transform: translateX(-3px);
}

.sigma_single-pagination-next:hover i {
    transform: translateX(3px);
}

/* 6.2. Sidebar */
.sidebar-widget {
  padding: 30px;
  border: 2px solid #efefef;
  background-color: #fff;
}

.sidebar-widget + .sidebar-widget {
    margin-top: 30px;
}

.sidebar-widget .widget-title {
  position: relative;
  padding-left: 45px;
  margin-bottom: 30px;
  font-size: 22px;
}
.sidebar-widget .widget-title::before{
  content: '';
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: #F74F22;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.sidebar-widget .widget-title::after{
  content: '';
  width: 5px;
  height: 3px;
  border-radius: 4px;
  background-color: #F74F22;
  display: block;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

.sidebar-widget ul.sidebar-widget-list {
    margin: 0;
    padding: 0;
}

.sidebar-widget ul.sidebar-widget-list ul {
    padding-left: 20px;
}

.sidebar-widget ul.sidebar-widget-list li {
    margin: 0;
}

.sidebar-widget ul.sidebar-widget-list li ul li:first-child {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li+li {
    margin-top: 20px;
}

.sidebar-widget ul.sidebar-widget-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777;
}

.sidebar-widget ul.sidebar-widget-list li a:hover {
    color: #F74F22;
}

.widget-about-author-inner {
  text-align: center;
}
.widget-about-author-bg{
  height: 150px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.widget-about-author-inner img {
    border-radius: 50%;
    width: 150px;
    margin: 0 auto 20px;
}

.widget-about-author-inner h5 {
    margin-bottom: 10px;
    font-size: 18px;
}
.widget-about-author-inner .sigma_sm{
  justify-content: center;
}
.widget-about-author-inner .sigma_sm li a{
  color: #777;
}
.widget-about-author-inner .sigma_sm li a:hover{
  color: #F74F22;
}

.widget-recent-posts .sigma_recent-post{
  display: flex;
  align-items: center;
}

.widget-recent-posts .sigma_recent-post>a {
    position: relative;
    display: block;
    overflow: hidden;
    margin-right: 20px;
    width: 75px;
}

.widget-recent-posts .sigma_recent-post>a img {
    transition: .3s;
}

.widget-recent-posts .sigma_recent-post>a img:hover {
    transform: scale(1.1) rotate(2deg);
}

.sidebar-widget.widget-recent-posts .sigma_recent-post>a img{
  border-radius: 50%;
}
.sidebar-widget.widget-recent-posts .sigma_recent-post h6 a{
  color: #022147;
}
.sidebar-widget.widget-recent-posts .sigma_recent-post h6 a:hover{
  color: #F74F22;
}

.sidebar-widget.widget-recent-posts .sigma_recent-post+.sigma_recent-post{
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

.widget-recent-posts .sigma_recent-post-body{
  flex: 1;
}
.widget-recent-posts .sigma_recent-post-body > a{
  color: #707070;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}
.widget-recent-posts .sigma_recent-post-body > a i{
  margin-right: 5px;
  color: #F74F22;
}
.widget-recent-posts .sigma_recent-post-body > a:hover{
  color: #ec6a47;
}

.widget-recent-posts .sigma_recent-post h6 {
  margin-bottom: 6px;
  line-height: 1.2;
  font-size: 14px;
}

.widget-recent-posts .sigma_recent-post h6 a {
    color: #fff;
}
.sigma_footer:not(.sigma_footer-dark) .widget-recent-posts .sigma_recent-post h6 a{
  color: #022147;
}
.sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a{
  background-color: #f7f7f7;
  color: #022147;
}
.sigma_footer:not(.sigma_footer-dark) .sigma_sm.square li a:hover{
  background-color: #F74F22;
  color: #fff;
}

.sigma_footer:not(.sigma_footer-dark) .widget-recent-posts .sigma_recent-post h6 a:hover,
.widget-recent-posts .sigma_recent-post h6 a:hover,
.widget-recent-posts .sigma_recent-post span a:hover {
    color: #F74F22;
}

.widget-recent-posts .sigma_recent-post span {
    font-size: 13px;
    line-height: 1.7;
    font-weight: 600;
    color: #022147;
}

.widget-recent-posts .sigma_recent-post span a {
    font-weight: 400;
    text-decoration: underline;
    color: #022147;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post {
  margin-top: 20px;
}

.sidebar-widget.widget-logo img{
  width: 180px;
}

.sidebar-widget.widget-categories ul.sidebar-widget-list ul{
  padding: 0;
}

.sidebar-widget.widget-categories ul li a{
  padding: 15px;
  background-color: #f7f7f7;
  color: #777;
  font-weight: 600;
}

.sidebar-widget.widget-categories ul li a span{
  border-radius: 50%;
  background-color: #F74F22;
  color: #fff;
  font-size: 13px;
  width: 30px;
  transition: .3s;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-widget.widget-categories ul li a:hover{
  background-color: #F74F22;
  color: #fff;
}

.sidebar-widget.widget-categories ul li a:hover span{
  background-color: #f0fff0;
  color: #F74F22;
}

.sidebar-widget.event-info li + li{
  margin-top: 15px;
}

.sidebar-widget.event-info li span{
  display: block;
  width: 43%;
  float: left;
  font-weight: 600;
}

.sidebar-widget.widget-speakers .card{
  background-color: #fff;
}
.sidebar-widget.widget-speakers .card .card-header{
  background-color: #fff;
  padding: 0 0 20px;
  margin-bottom: 18px;
  border-bottom: 1px solid #efefef;
}

.sidebar-widget.widget-speakers .card .card-body{
  padding: 0 0 18px;
}

.sidebar-widget.widget-speakers .card .speaker-image{
  margin-right: 15px;
  border-radius: 50%;
}

.sidebar-widget.widget-speakers .card .speaker-image img{
  border-radius: 50%;
}

.sidebar-widget.widget-speakers .card h6{
  font-size: 18px;
}
.sidebar-widget.widget-speakers .accordion .card-header::before,
.sidebar-widget.widget-speakers .accordion .card-header::after{
  content: none;
}


.sidebar-widget.widget-upcoming-events li{
  display: flex;
  align-items: center;
}

.sidebar-widget.widget-upcoming-events li + li{
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid #efefef;
}

.sidebar-widget.widget-upcoming-events li .date{
  color: #022147;
  font-weight: 600;
  border-right: 1px solid #efefef;
  padding-right: 20px;
  margin-right: 20px;
}

.sidebar-widget.widget-upcoming-events li .date span{
  display: block;
  line-height: 1;
  margin-bottom: 4px;
  color: #F74F22;
  font-weight: 600;
  font-size: 40px;
}

.sidebar-widget.widget-upcoming-events li h6{
  margin-bottom: 3px;
}

.sidebar-widget.widget-upcoming-events li p{
  font-size: 16px;
  margin-bottom: 0;
}

.tags a,
.tagcloud a,
.entry-content .wp-block-tag-cloud a {
    display: inline-block;
    font-size: 12px;
    padding: 3px 12px;
    color: #022147;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background-color: #efefef;
    margin: 6px 6px 0 0;
    border-radius: 0;
}

.tags a:hover,
.tagcloud a:hover,
.entry-content .wp-block-tag-cloud a:hover {
    background-color: #F74F22;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.twitter-item + .twitter-item{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
}

.sigma_newsletter-form {
    position: relative;
}

.sigma_newsletter-form.primary-bg {
    background-color: #F74F22;
}

.sigma_newsletter-form h5 {
    margin-bottom: 15px;
    position: relative;
    font-size: 24px;
}

.sigma_countdown-timer {
  text-align: center;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  font-weight: 600;
  margin: 20px 0 0;
  transition: .3s;
}

/* 6.3. Products */
.sigma_product {
    position: relative;
    margin-bottom: 30px;
}

.sigma_product .sigma_product-thumb {
  position: relative;
    overflow: hidden;
}

.sigma_product .sigma_product-thumb.thumb-sm {
    max-width: 200px;
    margin: 0 auto 20px;
}

.sigma_product .sigma_product-thumb img {
    transition: .3s;
    width: 100%;
}

.sigma_product .sigma_product-thumb:hover img {
    transform: scale(1.1) rotate(3deg);
}
.sigma_product .sigma_product-body{
  position: relative;
  text-align: center;
  padding: 20px;
  border: 2px solid #efefef;
  border-top: 0;
}
.sigma_product .sigma_product-body h5 {
    margin: 0 0 15px;
    font-size: 18px;
    word-break: break-word;
}

.sigma_product .sigma_product-body .sigma_product-title a {
    color: #022147;
}

.sigma_product .sigma_product-body .sigma_product-title a:hover {
    color: #ec6a47;
}

.sigma_product .sigma_badge-featured {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffa80a;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_badge-featured i {
    display: inline-block;
}

.sigma_product .sigma_badge-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  background-color: #022147;
  font-weight: 600;
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.7;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_product .sigma_rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sigma_product .sigma_rating-wrapper span {
    font-weight: 600;
}

.sigma_product-price{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_product-price span {
    display: block;
}

.sigma_product-price span:first-child {
    font-size: 14px;
    line-height: 1.2;
    color: #F74F22;
    font-weight: 700;
}

.sigma_product-price span+span {
    font-size: 14px;
    color: #777;
    font-weight: 400;
    line-height: 1;
    margin-left: 10px;
    text-decoration: line-through;
}

.sigma_product-stock {
    font-weight: 600;
}

.sigma_product-stock.instock {
    color: #58da58;
}

.sigma_product-stock.outofstock {
    color: #f54978;
}

.sigma_product .sigma_rating {
    margin-bottom: 0;
}

.sigma_product-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s;
    visibility: hidden;
}
.sigma_product:hover .sigma_product-controls{
    opacity: 1;
    visibility: visible;
}
.sigma_product-controls a {
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: #777;
}
.sigma_product-controls a + a{
  margin-left: 10px;
}

.sigma_product-controls a i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
}

.sigma_product-controls a:hover {
    background-color: #F74F22;
    color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.sigma_product-footer{
  display: flex;
  align-items: center;
}

.sigma_shop-global {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.sigma_shop-global p {
  color: #022147;
  font-weight: 600;
    margin-bottom: 0;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
    background-color: #F74F22;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #F74F22;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #F74F22;
}

/* 6.3.3 Products Quick View & Details */
.sigma_quick-view-modal .sigma_product-single-content {
    padding: 0 40px;
}

.sigma_quick-view-modal .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
}

.sigma_product-single-content .sigma_rating-wrapper {
    margin-bottom: 0;
}

.sigma_product-single-content .sigma_product-excerpt,
.sigma_product-single-content .sigma_product-variation-wrapper {
    margin-bottom: 25px;
}

.sigma_product-single-content .sigma_product-price {
    margin-bottom: 20px;
    justify-content: flex-start;
}

.sigma_product-single-content .sigma_product-price span:first-child {
    font-size: 32px;
}

.sigma_product-single-content .sigma_product-price span {
    display: inline-block;
}

.sigma_product-single-content .sigma_product-price span+span {
    margin-left: 10px;
}

.sigma_product-single-content .sigma_post-single-meta{
  border-bottom: 0;
  border-top: 1px solid #efefef;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.sigma_product-single-content .sigma_post-single-meta + h6{
  margin: 5px;
}

.sigma_product-atc-form .qty-outter {
    display: flex;
    align-items: center;
}

.sigma_product-atc-form .qty-outter .sigma_btn-custom+.qty {
    margin-left: 20px;
}

.sigma_product-atc-form .qty-inner{
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.sigma_product-atc-form .qty-inner h6{
  text-transform: uppercase;
  margin: 0 10px 0 0;
  font-size: 13px
}

.qty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.qty input {
    border: 0;
    outline: none;
    width: 30px;
    text-align: center;
    font-weight: 600;
    border: 0;
    margin: 0 5px;
}

.qty span.qty-subtract {
    border-right: 0;
    cursor: pointer;
}

.qty span.qty-add {
    border-left: 0;
    cursor: pointer;
}

.qty span:hover {
  color: #F74F22;
}

.qty span {
    font-size: 13px;
    transition: .3s;
    color: #022147;
    line-height: 1;
}

.sigma_product-radio label{
  cursor: pointer;
  font-weight: 700;
  color: #022147;
  font-style: normal;
}

.sigma_product-radio label input{
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.sigma_product-radio label span{
  position: relative;
  z-index: 1;
  font-size: 13px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_product-radio label span::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: -1;
  background-color: #F74F22;
}
.sigma_product-radio label input:checked + span::before{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_product-radio label input:checked + span{
  color: #fff;
}

.sigma_product-additional-info .nav{
  border-bottom: 1px solid #efefef;
}

.sigma_product-additional-info .nav-link {
    margin: 0;
    border-radius: 0;
    font-weight: 600;
    color: #777;
    border-bottom: 2px solid transparent;
}

.sigma_product-additional-info .nav-item+.nav-item .nav-link {
    margin-left: 10px;

}

.sigma_product-additional-info .nav-item+.nav-item .nav-link:hover{
  color: #F74F22;
}

.sigma_product-additional-info .tab-content {
    z-index: 1;
    margin-top: 30px;
}


.sigma_product-additional-info .tab-content table {
    margin: 0;
}

.sigma_product-additional-info .sigma_rating-wrapper,
.sigma_product-additional-info .comment-body .sigma_rating {
    margin-bottom: 20px;
}
.sigma_product-additional-info .tab-content .comment-form,
.sigma_product-additional-info .tab-content .comments-list{
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}
.sigma_product-additional-info .tab-content .comments-list .comment-item{
  padding: 0;
  border: 0;
}
.sigma_product-additional-info .comment-form {
    margin-bottom: 50px;
}

.sigma_product-single-controls {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma_product-single-controls .sigma_add-to-favorite {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    margin: 0 10px 0 0;
}

.sigma_product-single-controls .sigma_add-to-favorite:hover {
    background-color: #f7f7f7;
}

.sigma_product-single-controls .sigma_add-to-favorite i {
    font-size: 21px;
    color: #777;
}

.sigma_product-meta {
    margin: 20px 0 0;
}

.sigma_product-single-2 .sigma_product-meta {
    margin: 20px 0 30px;
}

.sigma_product-meta li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.sigma_product-meta li>span {
    font-weight: 600;
    color: #022147;
    width: 120px;
}

.sigma_product-meta li .sigma_product-meta-item span,
.sigma_product-meta li a {
    color: #777;
}

.sigma_product-meta li a:hover {
    color: #F74F22;
}

.sigma_product-single-thumb,
.sigma_product-single-zoom>img {
    background-color: #fff;
}

.sigma_product-single-thumb .slider{
  margin-bottom: 30px;
}


/* 6.3.4. Cart & Wishlist */
td.remove {
    width: 30px;
}

.sigma_cart-product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_cart-product-wrapper img {
    width: 50px;
    margin-right: 15px;
}

.sigma_cart-product-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.sigma_cart-product-wrapper h6 {
    margin-bottom: 0;
    font-size: 14px;
    transition: 0.3s;
}

.sigma_cart-product-wrapper h6 a {
    color: #022147;
}

.sigma_cart-product-wrapper h6 a:hover {
    color: #F74F22;
}

.sigma_cart-product-wrapper p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    color: #848486;
}

/* 6.3.5. Checkout */
.sigma_notice {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border: 2px solid #efefef;
}

.sigma_notice p {
    margin: 0;
    font-weight: 600;
}

.sigma_notice p a:hover {
    text-decoration: underline;
}

.sigma_notice-content {
    margin-bottom: 30px;
    display: none;
}

/* 6.3.6 Products (Recent View) */
.sigma_product-recent {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    border-radius: 8px;
    transition: .3s;
}

.sigma_product-recent:hover {
    background-color: #f7f7f7;
}

.sigma_product-recent .sigma_product-recent-thumb {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.sigma_product-recent+.sigma_product-recent {
    margin-top: 10px;
}

.sigma_product-recent img {
    width: 75px;
    margin-right: 10px;
}

.sigma_product-recent-content .sigma_product-price span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
}

.sigma_product-recent-content .sigma_product-price span+span {
    margin-left: 5px;
}

.sigma_product-recent-content h6 {
    margin-bottom: 5px;
    font-weight: 600;
}

.sigma_product-recent button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    margin-left: auto;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #efefef;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    width: 40px;
    height: 40px;
}

/* 6.5. Banners */
.sigma_banner .bottom-skew{
  z-index: 2;
}
.sigma_banner video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.sigma_banner .slick-track,
.sigma_banner .slick-slide {
    margin: 0;
}

.sigma_banner {
  position: relative;
  padding: 0;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.sigma_banner .section-title{
  max-width: 100%;
}
.sigma_banner .title{
    color: #022147;
    font-weight: 800;
}

.sigma_banner .badge{
  margin-bottom: 10px;
}

.sigma_banner p:first-child {
    margin-bottom: 10px;
}

.sigma_banner .sigma_contact-info-item p{
  color: #777;
}

.sigma_banner p {
    margin-bottom: 25px;
    color: #fff;
    max-width: 600px;
}

.sigma_banner .slick-dots {
    padding: 0 0 10px;
}

.sigma_banner .slick-dots li button {
    background-color: #fff;
}

.sigma_banner .slick-dots li.slick-active button {
    background-color: #F74F22;
}

.sigma_banner .sigma_banner-slider-inner .sigma_banner-text {
    position: relative;
    z-index: 1;
    width: 100%;
}
.sigma_banner .sigma_banner-slider-inner .sigma_banner-text .text-center p{
  margin: 0 auto 25px;
}

.banner-1.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 210px 0 120px;
}

.sigma_banner.banner-1 .sigma_arrows,
.sigma_banner.banner-3 .sigma_arrows{
  position: absolute;
  bottom: 40px;
  right: 65px;
  z-index: 1;
}
.sigma_banner.banner-1 .sigma_arrows,
.sigma_banner.banner-3 .sigma_arrows{
  border: 1px solid rgba(255,255,255,.4);
}

.sigma_banner.banner-1 .sigma_arrows .slick-arrow.slider-prev,
.sigma_banner.banner-3 .sigma_arrows .slick-arrow.slider-prev{
  border-right: 1px solid rgba(255,255,255,.4);
}
.sigma_banner.banner-1 .slick-dots{
  position: absolute;
  bottom: 70px;
  left: 30px;
  padding: 0;
}
.sigma_banner.banner-1 .slick-dots li button{
  background-color: #5f5f5f;
}
.sigma_banner.banner-1 .slick-dots li.slick-active button{
  background-color: #F74F22;
}

.banner-1.sigma_banner img{
  position: absolute;
  top:  -60px;
  right: 0;
}
.banner-1.sigma_banner img.left{
  right: auto;
  left: 0;
}

/* Style 2 */
.banner-2.sigma_banner .sigma_banner-slider-inner {
  overflow: hidden;
  position: relative;
  padding: 350px 0 300px;
}

.banner-2.sigma_banner .highlight-text{
  background-color: #fff;
  padding: 7px 26px;
  color: #022147;
  display: inline-flex;
  font-weight: 500;
  margin-bottom: 28px;
}

.banner-2.sigma_banner .title{
  font-size: 80px;
  line-height: 1;
}
.banner-2.sigma_banner p{
  font-size: 18px;
}

.sigma_banner-logo{
  position: absolute;
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
  background-color: #fff;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;
}
.sigma_banner-logo::before{
  content: '';
  position: absolute;
  top: -40px;
  left: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  display: block;
  border: 5px solid rgba(255,255,255,.4);
  border-radius: 50%;
}
.sigma_banner-logo .sigma_sm.square{
  margin-top: 50px;
}
.sigma_banner-logo .sigma_sm.square li a{
  background-color: #f7f7f7;
  color: #022147;
  border-radius: 50%;
}
.sigma_banner-logo .sigma_sm.square li a:hover{
  background-color: #022147;
  color: #fff;
}

/* Style 3 */
.banner-3.sigma_banner .slick-track {
  display: flex;
}

.banner-3.sigma_banner .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.banner-3.sigma_banner .slick-track,
.banner-3.sigma_banner {
    padding: 0;
}

.banner-3.sigma_banner .sigma_banner-slider-inner {
    overflow: hidden;
    position: relative;
    padding: 180px 0 210px;
}

.header-absolute + .banner-3.sigma_banner .sigma_banner-slider-inner{
  padding: 240px 0 330px;
}

.banner-3 > .container-fluid{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.banner-3 > .container-fluid .sigma_sm{
  justify-content: flex-end;
}
.banner-3 > .container-fluid .sigma_sm li a{
  color: #777;
}
.banner-3 > .container-fluid .sigma_sm li a:hover{
  color: #fff;
}

.banner-cta.sigma_box.primary-bg{
  background-color: #A71B19;
}
.banner-cta.sigma_box.secondary-bg{
  background-color: #022147;
}
.banner-cta.sigma_box .sigma_box-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.banner-cta.sigma_box .sigma_box-content h5{
  margin: 0 0 10px;
}
.banner-cta.sigma_box .sigma_box-content h5,
.banner-cta.sigma_box .sigma_box-content p{
  color: #fff;
}
.banner-cta.sigma_box .sigma_box-text{
  max-width: 350px;
}
.banner-cta.sigma_box img{
  position: absolute;
  bottom: 0;
  right: 20px;
  max-width: 250px;
  z-index: 0;
  display: block;
}
.banner-cta.sigma_box .circle{
  width: 190px;
  height: 190px;
  background-color: #ec6a47;
  border-radius: 50%;
  position: absolute;
  bottom: 40px;
  right: 120px;
}
.banner-cta.sigma_box .sigma_box-content p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.banner-cta.sigma_box .sigma_box-content > i{
  margin-top: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255,255,255, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: .3s;
}
.banner-cta.sigma_box .sigma_box-content > i:hover{
  background-color: #fff;
  color: #022147;
}
.banner-cta.sigma_box .sigma_box-content .form-control::placeholder{
  color: #fff;
}
.banner-cta.sigma_box .sigma_box-content .form-control{
  height: 50px;
  margin-top: 50px;
  background-color: transparent;
  color: #fff;
  border: 1px solid rgba(255,255,255,.4);
}

/* 6.6. Icon Blocks */
.sigma_block-box,
.sigma_icon-block {
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 0;
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
    transition: .3s;
}

.sigma_icon-block i {
    font-size: 40px;
    line-height: 1;
    margin: 0 0 15px;
    color: #777;
    display: inline-block;
}

.sigma_icon-block.has-link:hover {
    background-color: #f7f7f7;
}

.sigma_icon-block a {
    display: block;
}

.sigma_icon-block a h5 {
    font-size: 14px;
}

.sigma_icon-block h5 {
    font-size: 22px;
    margin-bottom: 0;
}

.sigma_icon-block h5+p {
    margin-top: 10px;
}

.sigma_icon-block p {
    margin-bottom: 0;
}

.sigma_icon-block svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* Style 2 & 3 */

.sigma_icon-block.icon-block-2,
.sigma_icon-block.icon-block-3{
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: none;
  padding: 0;
}
.sigma_icon-block.icon-block-2{
  padding: 40px;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.sigma_icon-block.icon-block-2 i,
.sigma_icon-block.icon-block-3 i{
  margin: 0;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #A71B19;
  margin-right: 20px;
}
.sigma_icon-block.icon-block-2 i{
  font-size: 70px;
}
.sigma_icon-block.icon-block-3 img{
  margin-right: 20px;
  max-width: 100px;
}

.sigma_icon-block.icon-block-2 .sigma_icon-block-content i{
  font-size: 14px;
  margin: 0;
  display: inline-block;
  margin-top: 20px;
  color: #777;
  opacity: .3;
}
.sigma_icon-block.icon-block-2 h5+p,
.sigma_icon-block.icon-block-3 h5+p{
  margin-top: 5px;
}

.sigma_icon-block.icon-block-2 .sigma_icon-block-content,
.sigma_icon-block.icon-block-3 .sigma_icon-block-content{
  flex: 1;
}

/* Style 4 */
.sigma_icon-block.icon-block-4{
  border: 0;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.sigma_icon-block.icon-block-4 i{
  position: relative;
  margin-right: 15px;
  margin-bottom: 0;
  font-size: 34px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #F74F22;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F74F22;
}
.sigma_icon-block.icon-block-4 i::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 1px;
  background: linear-gradient(to bottom, #F74F22, transparent) ;
}
.sigma_icon-block.icon-block-4 .sigma_icon-block-content{
  flex: 1;
}

/* Style 5 */
.sigma_icon-block.icon-block-5{
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  text-align: center;
}
.sigma_icon-block.icon-block-5 .icon-wrapper{
  padding: 60px 20px;
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 35px;
  color: #F74F22;
  z-index: 1;
  transition: .3s;
  display: block;
  text-align: center;
  box-shadow: 0 -3px 6px rgba(0,0,0,.06);
}
.sigma_icon-block.icon-block-5 .icon-wrapper i{
  font-size: 50px;
}
.sigma_icon-block.icon-block-5 .icon-wrapper:hover{
  background-color: #022147;
}
.sigma_icon-block.icon-block-5 .icon-wrapper:hover i{
  color: #fff;
}
.sigma_icon-block.icon-block-5 h5{
  font-size: 18px;
  color: #777;
  max-width: 180px;
  margin: 0 auto;
}


/* Style 6 and 7 */
.sigma_icon-block.icon-block-6,
.sigma_icon-block.icon-block-7{
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}
.sigma_icon-block.icon-block-6 i,
.sigma_icon-block.icon-block-7 i{
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto 20px;
  font-size: 50px;
  border: 3px solid #A71B19;
  color: #A71B19;
}
.sigma_icon-block.icon-block-6 h5,
.sigma_icon-block.icon-block-7 h5{
  font-size: 24px;
}

.sigma_icon-block.icon-block-6 .count,
.sigma_icon-block.icon-block-7 .count{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 120px;
  opacity: .2;
  line-height: 1;
  z-index: -1;
}

.sigma_icon-block.icon-block-7 {
  box-shadow: none;
  border: 0;
  text-align: left;
  padding: 40px;
  background-color: #282828;
  max-width: 300px;
}

.sigma_icon-block.icon-block-7 i{
  width: auto;
  height: auto;
  font-size: 60px;
  display: inline-flex;
  justify-content: flex-start;
  background-color: transparent;
  border: 0;
}
.sigma_icon-block.icon-block-7 > i{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 140px;
  opacity: .1;
  color: #fff;
}
.sigma_icon-block.icon-block-7 p,
.sigma_icon-block.icon-block-7 h5{
  color: #fff;
}

.sigma_icon-block.icon-block-7 .sigma_search-adv-input{
  margin-top: 20px;
}
.sigma_icon-block.icon-block-7 .form-control{
  background-color: #022147;
}
.sigma_icon-block.icon-block-7 button i{
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.sigma_icon-block.icon-block-7.text-center{
  max-width: 100%;
}

.sigma_icon-block.icon-block-7.text-center i{
  margin: 0;
  margin-top: 20px;
}

.sigma_icon-block.icon-block-7.text-center > i{
  margin: 0;
  top: 50%;
  left: 50%;
  right: auto;
  color: #022147;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}
.sigma_icon-block.icon-block-7.text-center:hover{
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  border-color: transparent;
}
.sigma_icon-block.icon-block-7.text-center:hover > i{
  opacity: .1;
  visibility: visible;
}
.sigma_icon-block.icon-block-7.text-center .sigma_icon-block-content > span{
  color: #A71B19;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
}
.sigma_icon-block.icon-block-7.text-center span i{
  font-size: 14px;
  margin-left: 5px;
}

.sigma_icon-block.icon-block-7.light{
  background-color: #fff;
  border: 2px solid #efefef;
}

.sigma_icon-block.icon-block-7.light h5{
  color: #022147;
}
.sigma_icon-block.icon-block-7.light p{
  color: #767e88;
}

/* Style 8 */
.sigma_icon-block.icon-block-8{
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}
.sigma_icon-block.icon-block-8 .icon-wrapper{
  margin-right: 10px;
}
.sigma_icon-block.icon-block-8  h6{
  color: #fff;
  margin: 0;
  font-size: 14px;
}
.sigma_icon-block.icon-block-8 .icon-wrapper i{
  opacity: .3;
}
.sigma_icon-block.icon-block-8 .icon-wrapper i,
.sigma_icon-block.icon-block-8 .sigma_rating,
.sigma_icon-block.icon-block-8 .sigma_rating i{
  margin: 0;
}
.sigma_icon-block.icon-block-8 span{
  font-weight: 600;
}

/* 6.7. DONNATE TO HELPs */
.sigma_cta-notice {
    padding: 40px;
    border-radius: 8px;
}

.sigma_cta-notice-inner {
    max-width: 600px;
}

.sigma_cta-notice-inner p {
    margin-bottom: 25px;
}

.sigma_cta{
  padding: 30px 30px 30px 50px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.sigma_cta.sm{
  padding: 30px;
}
.sigma_cta h4{
  margin: 0;
  font-size: 36px;
}
.sigma_cta img{
  position: absolute;
  bottom: 0;
  left: -100px;
}
.sigma_cta.lg img{
  left: -300px;
}
.sigma_cta .sigma_cta-content{
  padding-right: 100px;
}
.sigma_cta.sm .sigma_cta-content{
  padding-right: 0;
}
.sigma_cta img + .sigma_cta-content{
  padding-left: 60px;
  padding-right: 0;
}
.sigma_cta.lg img + .sigma_cta-content{
  padding-left: 220px;
}
.sigma_cta .sigma_cta-content + img{
  left: auto;
  right: -100px;
}
.sigma_cta-sperator{
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  color: #022147;
  font-weight: 600;
  background-color: #fff;
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
  box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
  justify-content: center;
}

.sigma_cta.sm .sigma_cta-sperator{
  left: 0;
}

/* 6.8. Login & Signup */
.sigma_auth-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
}

.sigma_auth-description {
    width: 600px;
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sigma_auth-description-inner {
    text-align: center;
    max-width: 400px;
}

.sigma_auth-description-inner h2 {
    margin: 0;
    color: #fff;
}

.sigma_auth-description-inner p {
    margin: 30px 0;
    color: #fff;
}

.sigma_auth-description-inner i {
    color: #fff;
    line-height: 1;
    font-size: 40px;
    margin-bottom: 30px;
    display: block;
}

.sigma_social-login {
    margin-bottom: 20px;
}

.sigma_social-login+p a {
    display: inline-block;
}

.sigma_auth-form {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #f7f7f7;
    padding: 60px;
    text-align: center;
}

.sigma_auth-form h2 {
    margin-bottom: 50px;
}

.sigma_auth-form form {
    max-width: 400px;
    margin: 0 auto;
}

.sigma_auth-form .sigma_btn-custom {
    margin-top: 20px;
}

.sigma_auth-form a {
    display: block;
    color: #022147;
    text-decoration: underline;
}

.sigma_auth-seperator {
    margin: 30px 0;
    text-align: center;
}

.sigma_auth-seperator span {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #9F9E9E;
    font-size: 13px;
    font-weight: 500;
}

.sigma_auth-seperator span::before {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-right: 20px;
}

.sigma_auth-seperator span::after {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    height: 1px;
    margin-left: 20px;
}

.sigma_social-login-btn {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    line-height: 24px;
    border: 0;
    font-size: 14px;
    padding: 20px 20px;
    border-radius: 3px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.sigma_social-login-btn i {
    margin-right: 5px;
}

.sigma_auth-mini {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
}

.sigma_auth-mini h5 {
    margin-bottom: 15px;
}

.sigma_auth-mini a {
    display: block;
    color: #022147;
    text-decoration: underline;
}

.sigma_auth-mini a:hover {
    color: #F74F22;
}

/* 6.9. Error 404 */
.section.error{
  padding: 120px 0 480px;
}
.section.error h1{
  font-size: 130px;
  line-height: 1;
  margin: 0 0 40px;
}

/* 6.12 volunteers Members */
.sigma_volunteers{
  position: relative;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  margin-bottom: 30px;
  border-bottom: 4px solid #F74F22;
}
.sigma_volunteers-thumb{
  overflow: hidden;
}
.sigma_volunteers-thumb img{
  width: 100%;
  transition: .3s;
}
.sigma_volunteers-thumb:hover img{
  transform: scale(1.1);
}
.sigma_volunteers-body{
  text-align: center;
  padding: 20px;
}

.sigma_volunteers-info h5{
  margin-bottom: 5px;
}
.sigma_volunteers-info p{
  font-size: 15px;
  line-height: 24px;
  color: #F74F22;
  padding: 0;
}
.sigma_volunteers-info{
  margin: 0 0 15px;
}
.sigma_volunteers-sm .sigma_sm{
  justify-content: center;
}
.sigma_volunteers-sm .sigma_sm li a{
  display: flex;
  font-size: 12px;
}
.sigma_volunteers-sm .sigma_sm li + li{
  margin-left: 15px;
}

/* Style 2 */
.sigma_volunteers.volunteers-2{
  background-color: transparent;
  border-bottom: 0;
  z-index: 1;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-info{
  margin: 0;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-info h5{
  margin: 0;
  font-size: 18px;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-info p{
  margin: 0 0 10px;
}

.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-sm .sigma_sm{
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  flex-direction: column;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-sm li a{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #F74F22;
  justify-content: center;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-sm li a:hover{
  background-color: #ec6a47;
}
.sigma_volunteers.volunteers-2 .sigma_volunteers-body .sigma_volunteers-sm li + li{
  margin: 0;
}

.sigma_volunteers.volunteers-2:hover::before,
.sigma_volunteers.volunteers-2:hover .sigma_volunteers-body{
  opacity: 1;
  visibility: visible;
}
.sigma_volunteers.volunteers-2::before{
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba( 0,0,0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}

.sigma_volunteers.volunteers-2 .sigma_volunteers-sm .sigma_sm li a,
.sigma_volunteers.volunteers-2 .sigma_volunteers-info p,
.sigma_volunteers.volunteers-2 .sigma_volunteers-info h5{
  color: #fff;
}

/* Style 3 */
.sigma_volunteers.volunteers-3{
  padding: 20px;
  border-bottom: 0;
  text-align: center;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-body{
  padding: 0;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-thumb{
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px );
  height: calc(100% - 20px );
  margin: 10px;
  border-radius: 50%;
  background-color: #022147;
  opacity: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  transform: scale(0);
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-thumb::after{
  content: "\f067";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  font-size: 20px;
  transition: .3s;
  transform: translate(-50%, -50%) scale(0);
}
.sigma_volunteers.volunteers-3:hover .sigma_volunteers-thumb::before{
  transform: scale(1);
  opacity: .6;
  visibility: visible;
}
.sigma_volunteers.volunteers-3:hover .sigma_volunteers-thumb::after{
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-thumb:hover img{
  transform: scale(1);
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-thumb img{
  border-radius: 50%;
  width: 120px;
  margin: 0 auto;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-info img{
  width: 30px;
  margin-bottom: 20px;
}
.sigma_volunteers.volunteers-3 .sigma_volunteers-info i{
  font-size: 24px;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
}

/* Style 4 & 5 */
.sigma_volunteers.volunteers-5,
.sigma_volunteers.volunteers-4{
  background-color: transparent;
  box-shadow: none;
  border-bottom: 0;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb{
  position: relative;
  width: 200px;
  margin: 0 auto 20px;
  border-radius: 50%;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-info p,
.sigma_volunteers.volunteers-4 .sigma_volunteers-info p{
  margin: 0 0 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-info h5,
.sigma_volunteers.volunteers-4 .sigma_volunteers-info h5{
  margin: 0;
}

.sigma_volunteers.volunteers-4 .sigma_volunteers-info h5.text-white a{
  color: #fff;
}

.sigma_volunteers.volunteers-4 .sigma_volunteers-info h5.text-white a:hover{
  color: #ec6a47;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb{
  border-radius: 50%;
  overflow: visible;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb img,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb img{
  border-radius: 50%;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb:hover img,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb:hover img{
  transform: scale(1);
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li{
  position: absolute;
  top: 0;
  right: 25px;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm a{
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #022147;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition-duration: .3s;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-body,
.sigma_volunteers.volunteers-4 .sigma_volunteers-body{
  padding: 0;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials{
  border: 2px solid #fff;
  opacity: 1;
  visibility: visible;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials i,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials i{
  transition: .3s;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm.visible a.trigger-volunteers-socials i,
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials:hover i,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm.visible a.trigger-volunteers-socials i,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials:hover i{
  transform: rotate(45deg);
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm.visible a,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm.visible a{
  opacity: 1;
  visibility: visible;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm.visible a.trigger-volunteers-socials,
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a:hover,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm.visible a.trigger-volunteers-socials,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm a:hover{
  background-color: #F74F22;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:hover,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:hover{
  opacity: 1;
  visibility: visible;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(2),
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(2){
  top: 45px;
  right: -10px;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(3),
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(3){
  top: 100px;
  right: -15px;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(4),
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(4){
  top: 150px;
  right: 15px;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(2) a,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(2) a{
  transition-delay: .1s;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(3) a,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(3) a{
  transition-delay: .2s;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(4) a,
.sigma_volunteers.volunteers-4 .sigma_volunteers-thumb .sigma_sm li:nth-child(4) a{
  transition-delay: .3s;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb img{
  border-radius: 0;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm{
  position: absolute;
  top: 10px;
  right: 10px;
  flex-direction: column;
}

.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(4),
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(3),
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li:nth-child(2),
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li{
  position: relative;
  top: auto;
  right: auto;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li + li{
  margin: 0;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li a{
  border-radius: 0;
  background-color: #fff;
  color: #022147;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm li a:hover{
  color: #fff;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb .sigma_sm a.trigger-volunteers-socials{
  border: 0;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-thumb{
  width: auto;
  margin: 0;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-info{
  margin: 0;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-body{
  padding: 20px;
  background-color: #fff;
}
.sigma_volunteers.volunteers-5 .sigma_volunteers-info h5{
  font-size: 20px;
}


/*----- volunteers Details----- */

.sigma_volunteer-detail .sigma_member-image{
  position: relative;
  overflow: hidden;
  height: 550px;
}

.sigma_volunteer-detail .sigma_member-image img{
  width: 100%;
}

.sigma_volunteer-detail .sigma_volunteer-detail-category{
  color: #F74F22;
  font-size: 16px;
  font-weight: 600;
}

.sigma_volunteer-detail .sigma_member-name{
  margin-bottom: 12px;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info{
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #efefef;
  margin-bottom: 0;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info li{
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info li i{
  font-size: 20px;
  color: #F74F22;
  margin-right: 30px;
  width: 15px;
}
.sigma_volunteer-detail .sigma_volunteer-detail-info li + li{
  margin-top: 20px;
}
.sigma_volunteer-detail .sigma_volunteer-detail-info li .title{
  color: #022147;
  font-weight: 600;
  padding-right: 12px;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info li a{
  color: #777;
}
.sigma_volunteer-detail .sigma_volunteer-detail-info li a:hover{
  color: #F74F22;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li{
   flex-direction: column;
   align-items: flex-start;
   padding-left: 40px;
   position: relative;
   font-size: 16px;
   padding-bottom: 20px;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li + li{
  margin-top: 0;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li:last-child{
  padding-bottom: 0;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li:before{
  content: "";
  position: absolute;
  left: 2px;
  top: 10px;
  width: 3px;
  height: 100%;
  border-radius: 0;
  background-color: #F74F22;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li:after{
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F74F22;
}

.sigma_volunteer-detail .sigma_volunteer-detail-info.has-element li:last-child:before{
  content: none;
}


/* 6.13. Pricing */
.sigma_pricing{
  background-image: url(../img/pricing.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 26px 20px 44px 20px;
  margin-bottom: 30px;
}
.sigma_pricing .sigma_pricing-icon{
  margin-bottom: 18px;
}
.sigma_pricing-title{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}
.sigma_pricing-price{
  position: relative;
  display: inline-block;
  line-height: 1;
}
.sigma_pricing-price .sigma_pricing-currency{
  position: absolute;
  top: 0;
  left: -12px;
  font-size: 20px;
  font-weight: 400;
}
.sigma_pricing-price span{
  font-size: 48px;
  font-weight: 800;
  color: #F74F22;
}
.sigma_pricing .list-style-none{
  margin-top: 20px;
}
.sigma_pricing .sigma_btn-custom{
  margin-top: 35px;
}

.sigma_pricing .list-style-none li i{
  margin-right: 10px;
}

/* Style 2 */
.sigma_pricing.pricing-2{
  background-image: none;
  background-color: #fff;
  padding: 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_pricing.pricing-2::before{
  content: '';
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  right: -100px;
  top: -90px;
  background-color: #f0fff0;
  border-radius: 50%;
}
.sigma_pricing.pricing-2 > i{
  position: absolute;
  top: 10px;
  right: 40px;
  font-size: 60px;
  color: #F74F22;
}

.sigma_pricing.pricing-2.main > i{
  color: #fff;
}
.sigma_pricing.pricing-2.main::before{
  background-color: #F74F22;
}

.sigma_pricing.pricing-2 .sigma_pricing-price{
  margin-left: 10px;
}

.sigma_pricing.pricing-2 .list-style-none{
  margin: 40px 0 0;
}

/* Style 3 */
.sigma_pricing.pricing-3{
  background-image: none;
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  text-align: center;
}
.sigma_pricing.pricing-3 .sigma_pricing-info{
  padding: 40px;
  border: 2px solid #efefef;
}
.sigma_pricing.pricing-3 .sigma_pricing-info h5{
  font-size: 14px;
  color: #F74F22;
}

.sigma_pricing.pricing-3.main .sigma_pricing-info{
  background-color: #F74F22;
}
.sigma_pricing.pricing-3.main .sigma_pricing-info h5{
  color: #fff;
}
.sigma_pricing.pricing-3.main .sigma_pricing-price span{
  color: #fff;
}

.sigma_pricing.pricing-3 .sigma_pricing-price{
  margin-left: 10px;
}
.sigma_pricing.pricing-3 .sigma_pricing-price span{
  color: #022147;
}

.sigma_pricing.pricing-3 .list-style-none{
  padding: 20px 20px 30px;
  margin: 0;
}
.sigma_pricing.pricing-3 .sigma_btn-custom{
  margin-top: 20px;
}

/* 6.14. Timeline */
.sigma_timeline-nodes {
  margin-bottom: 50px;
  position: relative;
}
.sigma_timeline-nodes:nth-child(even) {
  flex-direction: row-reverse;
}
.sigma_timeline-nodes:nth-child(even) .sigma_timeline-date {
  text-align: right;
}
.sigma_timeline-nodes::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 49.99999%;
  width: 0;
  border-left: 1px solid #F74F22;
  opacity: .3;
  height: calc(100% + 50px);
  z-index: 1;
  transform: translateX(-50%);
}
.sigma_timeline-nodes:last-child::before{
  display: none;
}
.sigma_timeline-element h4 {
  font-size: 24px;
  line-height: 45px;
  font-weight: 600;
}
.sigma_timeline-nodes:nth-child(odd) h4,
.sigma_timeline-nodes:nth-child(odd) p {
  text-align: right;
}
.sigma_timeline-nodes:nth-child(odd) h4,
.sigma_timeline-nodes:nth-child(odd) p{
  text-align: right;
}
.sigma_timeline-element p,
.sigma_timeline-element time {
  color: #555b62;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
}
.sigma_timeline-image {
  position: relative;
  z-index: 2;
}
.sigma_timeline-image i {
  background: #fff;
  padding: 5px;
  color: #F74F22;
  border-radius: 50%;
  margin-top: 0;
  font-size: 24px;
}
.sigma_timeline-nodes:nth-child(odd) .sigma_timeline-date{
  text-align: left;
}
.sigma_timeline-date span {
  font-size: 20px;
  font-weight: 700;
  color: #022147;
}
.sigma_timeline-date span.sigma_timeline-month {
  text-align: center;
  line-height: 20px;
  color: #555b62;
  margin-top: 8px;
  font-size: 14px;
  text-transform: uppercase;
}

/* 6.15. Contact */
.sigma_contact-info{
  padding: 0;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sigma_contact-info h3{
  font-weight: 800;
}
.sigma_contact-info h6{
  margin-bottom: 5px;
}
.sigma_contact-info-item p{
  margin: 0;
}
.sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 20px;
}
.sigma_contact-info .sigma_sm a{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(255,255,255,.1);
}

.sigma_contact-info .sigma_sm a:hover{
  background-color: #F74F22;
}
.sigma_contact-bg{
  height: 100%;
}

.sigma_contact-info-item + .sigma_contact-info-item{
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #efefef;
}

.sigma_contact-info-item .sigma_sm li a{
  color: #777;
  font-size: 18px;
}
.sigma_contact-info-item .sigma_sm li a:hover{
  color: #F74F22;
}

/*======================
7. Misc
========================*/
.slick-list {
    margin: 0 -15px;
}

.slick-slide {
    outline: none;
    margin: 0 15px;
}

/* Arrows */
.sigma_arrows {
    display: inline-flex;
    align-items: center;
}

.sigma_arrows .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #022147;
    transition: .3s;
    cursor: pointer;
    color: #777;
}
.sigma_arrows.style-2{
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_arrows.style-2 .slick-arrow{
  background-color: #f7f7f7;
}
.sigma_arrows .slick-arrow + .slick-arrow{
  margin-left: 10px;
}
.sigma_arrows .slick-arrow:hover {
    color: #fff;
    background-color: #A71B19;
}
.sigma_arrows.sigma_arrows-transparent .slick-arrow:hover,
.sigma_arrows.sigma_arrows-transparent .slick-arrow{
  background-color: transparent;
  color: #fff;
}

.sigma_arrows.sigma_arrows-dark .slick-arrow:hover,
.sigma_arrows.sigma_arrows-dark .slick-arrow{
  color: #022147;
}
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-circle,
.sigma_arrows.sigma_arrows-dark .sigma_round-arrow .sigma_round-arrow-stroke{
  color: #022147;
  stroke: #022147;
}
.sigma_arrows.sigma_arrows-sm .sigma_round-arrow svg{
  width: 40px;
  height: 40px;
}
.sigma_arrows .sigma_round-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sigma_arrows .sigma_round-arrow svg{
  width: 50px;
  height: 50px;
}
.sigma_arrows .sigma_round-arrow.prev-arrow{
  margin-right: 10px;
}
.sigma_arrows .sigma_round-arrow i{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}
.sigma_arrows .sigma_round-arrow .slick-arrow.slider-prev{
  margin: 0;
}

.sigma_arrows .sigma_round-arrow .sigma_round-arrow-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow .sigma_round-arrow-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.sigma_arrows .sigma_round-arrow:hover .sigma_round-arrow-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.sigma_arrows-absolute-center .sigma_round-arrow{
  position: absolute;
  top: 50%;
  margin-right: 0;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 35px;
}
.sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 35px;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.next-arrow{
  right: 15px;
  opacity: 1;
  visibility: visible;
}
.section:hover .sigma_arrows-absolute-center .sigma_round-arrow.prev-arrow{
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.slick-prev,
.slick-next {
    background-color: #022147;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #022147;
    transition: .3s;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
}

.slick-slider:hover .slick-next,
.section:hover .slick-next {
    right: 25px;
    opacity: 1;
    visibility: visible;
}

.slick-slider:hover .slick-prev,
.section:hover .slick-prev {
    left: 25px;
    opacity: 1;
    visibility: visible;
}

.slick-prev:before,
.slick-next:before {
    color: #ffffff;
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1.7;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    font-size: 14px;
}

.slick-prev:before {
    content: "\f053";
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: #fff;
    background-color: #F74F22;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
    color: #fff;
}

/* Dots */
.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 25px;
    justify-content: flex-start;
}

.slick-dots li {
    margin: 0 5px;
    text-align: center;
}

.slick-dots li button {
    font-size: 0;
    width: 30px;
    height: 7px;
    background-color: #efefef;
    border: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
}

.slick-dots li.slick-active button {
    background-color: #F74F22;
    width: 50px;
}

.light-dots .slick-dots li button{
  background-color: #fff;
}
.light-dots .slick-dots li.slick-active button {
    background-color: #F74F22;
}


.slick-slide {
    outline: none;
}

/* custom Dots */
.slick-dots .sigma_round-dot{
  position: relative;
  cursor: pointer;
}
.slick-dots .sigma_round-dot::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  transition: .3s;
  opacity: .6;
}
.slick-dots .sigma_round-dot svg{
  width: 30px;
  height: 30px;
}
.slick-dots .sigma_round-dot .sigma_round-dot-stroke{
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  opacity: .5;
  color: #fff;
}
.slick-dots .sigma_round-dot .sigma_round-dot-circle{
  fill: none;
  stroke: currentColor;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  color: #fff;
}
.slick-dots .slick-active .sigma_round-dot-circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 700;
}
.slick-dots .slick-active .sigma_round-dot::before,
.slick-dots .sigma_round-dot:hover::before{
  opacity: 1;
}

.slick-dots .sigma_round-dot.dot-dark .sigma_round-dot-circle,
.slick-dots .sigma_round-dot.dot-dark .sigma_round-dot-stroke{
  color: #022147;
}
.slick-dots .sigma_round-dot.dot-dark::before{
  background-color: #022147;
}

/* pagination */
.pagination {
    margin-top: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.pagination .page-item {
    margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link {
    border-radius: 0;
}

.pagination .page-link {
  color: #022147;
  border: 0;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
  line-height: 1.25;
}

.page-item:not(:first-child) .page-link{
  margin-left: 15px;
}

.pagination .page-item.active .page-link {
    background-color: #022147;
    border: 1px solid #022147;
}

.pagination .page-item:not(.active) .page-link:hover,
.pagination .page-item:not(.active) .page-link:focus {
    color: #F74F22;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tooltip {
    font-size: 13px;
}

.load-more {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    padding: 10px;
    border: 1px solid #efefef;
    border-radius: 8px;
    font-weight: 600;
    background-color: #fff;
}

.load-more:hover {
    background-color: #f7f7f7;
}

/* Progress */
.sigma_progress{
  position: relative;
  font-weight: 700;
  color: #022147;
}
.sigma_progress h6{
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}

.sigma_progress .sigma_progress-count{
  position: absolute;
  top: 0;
  transition: 3s;
}

.sigma_progress + .sigma_progress{
  margin-top: 30px;
}

.progress{
  background-color: #022147;
  height: 4px;
  border-radius: 0;
  overflow: visible;
}

.progress .progress-bar{
  position: relative;
  background-color: #F74F22;
  transition: 3s;
  overflow: visible;
}

.progress .progress-bar span{
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #F74F22;
  transform: translateY(-50%);
}

/* Accordion */
.accordion .card {
    margin-bottom: 0;
}
.accordion .card {
  background-color: #f7f7f7;
}

.accordion .card .card-header .btn {
  position: relative;
  background-color: #f7f7f7;
  border-bottom: 0;
  padding: .75rem 1.25rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #022147;
  font-weight: 600;
}

.accordion .card-header .btn[aria-expanded="true"] {
    background-color: #F74F22;
    color: #fff;
}

.accordion .card-header .btn::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
}

.accordion .card-header .btn::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.accordion .card-header .btn[aria-expanded="true"]::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}

.accordion .card-header .btn[aria-expanded="true"]::after,
.accordion .card-header .btn[aria-expanded="true"]::before {
    background-color: #fff;
}

.accordion .card-header .btn i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    font-size: 20px;
}

.accordion .card-header .btn[aria-expanded="true"] i {
    color: #F74F22;
}

.accordion .card {
    border: #f7f7f7;
}

.accordion.with-gap .card-header {
  border-radius: 0;
}

.accordion.with-gap .card {
    margin-bottom: 10px;
}

.accordion.with-gap .card:last-child {
    margin-bottom: 0;
}

.accordion.with-gap .card:first-of-type,
.accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #efefef;
}

/* Rating */
.sigma_rating-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sigma_rating-wrapper>span {
    margin-left: 10px;
    color: #848486;
    font-size: 13px;
}

.sigma_rating {
    margin-bottom: 5px;
}

.sigma_rating i {
    margin-right: 5px;
    font-size: 12px;
    color: #848486;
}

.sigma_rating i.active {
    color: #ffa80a;
}

.mt-negative {
    margin-top: -150px;
    position: relative;
    z-index: 1;
}
.mt-negative-md{
  margin-top: -90px;
  position: relative;
  z-index: 1;
}
.mt-negative-sm{
  margin-top: -30px;
}
.mt-negative-sm + .mt-negative-sm{
  margin-top: -60px;
}

.mb-negative{
  margin-bottom: -85px;
  z-index: 11;
  position: relative;
  display: block;
}
.mb-negative + .sigma_footer{
  padding-top: 85px;
}

.section.section-padding.mt-negative,
.section.mt-negative{
  padding-top: 0;
}

.custom_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sigma_block-box {
    padding: 45px 40px;
    border-radius: 0;
    margin-bottom: 30px;
}

.vertical-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sigma_block-box h5 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
}

.sigma_block-box h4 {
    margin-bottom: 10px;
}
.sigma_block-box img{
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.sigma_block-box .sigma_audio-player{
  margin-bottom: 0;
}
.sigma_block-box .sigma_block-content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sigma_block-box .sigma_block-content li{
  margin: 0;
}
.sigma_block-box .sigma_block-content li + li{
  margin-top: 10px;
}

/*Video box*/
.sigma_video-popup-wrap{
  position: relative;
  transition: 0.3s;
}
.sigma_video-popup-wrap .sigma_video-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.sigma_video-popup{
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #A71B19;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.sigma_video-popup::before{
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.sigma_video-popup::after{
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOutSM;
  opacity: 0;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}
.sigma_video-popup.bg-white{
  color: #F74F22;
}
.sigma_video-popup.bg-white:hover{
  background-color: #F74F22 !important;
  color: #fff;
}

.sigma_video-popup.popup-lg{
  width: 140px;
  height: 140px;
  font-size: 20px;
}
.sigma_video-popup.popup-sm{
  width: 50px;
  height: 50px;
}
.sigma_video-popup.popup-dark{
  background-color: #2d2d2d;
  color: #fff;
}

.sigma_video-popup.secondary{
  color: #022147;
}
.sigma_video-popup.secondary::after{
  background-color: #022147;
}

.sigma_video-popup:hover{
  color: #fff;
}

.sigma_broadcast-video .sigma_video-popup-wrap:hover{
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 52%);
}

.sigma_broadcast-video .sigma_video-popup-wrap img{
  width: 100%;
}

/*sigma_testimonials*/
.sigma_testimonial{
    padding: 40px;
    background-color: #fff;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_testimonial .sigma_testimonial-inner>i{
    font-size: 50px;
    margin-bottom: 25px;
    line-height: 1;
}
.sigma_testimonial .sigma_testimonial-inner>p{
    font-size: 15px;
    line-height: 30px;
    margin: 0 auto 20px;
}
.sigma_testimonial .sigma_testimonial-inner cite{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
}

/* Style 2 */
.sigma_testimonial.style-2{
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner{
  position: relative;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 10px 20px 0px rgba(53,82,99,0.09);
  display: flex;
  align-items: flex-start;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner .sigma_testimonial-body p{
  color: #022147;
  font-size: 14px;
  margin: 0;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner  .sigma_testimonial-thumb{
  position: relative;
  margin-right: 20px;
  width: 100px;
}
.sigma_testimonial.style-2 .sigma_testimonial-inner  .sigma_testimonial-thumb::before{
  content: '\f105';
  font-family: flaticon;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background-color: #A71B19;
}
.sigma_testimonial.style-2 .sigma_testimonial-inner  .sigma_testimonial-thumb + div{
  flex: 1;
}
.sigma_testimonial.style-2 .sigma_testimonial-inner .sigma_testimonial-body{
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner>i{
  position: absolute;
  top: -20px;
  line-height: 1;
  right: 0px;
  background-color: #F74F22;
  color: #fff;
  border-radius: 50%;
  font-size: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sigma_testimonial.style-2 .sigma_testimonial-inner cite{
  color: #022147;
  display: block;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner .sigma_rating-wrapper{
  margin-left: auto;
}

.sigma_testimonial.style-2 .sigma_testimonial-inner h5{
  margin: 5px 0 15px;
}

.sigma_testimonial.style-2 .sigma_testimonial-footer{
  display: flex;
  align-items: center;
}

.sigma_testimonial + .sigma_arrows{
  margin-top: 30px;
}

/* Style 3 */
.sigma_testimonial.style-3{
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.sigma_testimonial.style-3 .sigma_testimonial-inner{
  position: relative;
}
.sigma_testimonial.style-3 .sigma_testimonial-inner p{
  font-size: 20px;
  color: #022147;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  padding: 20px 30px;
}

.sigma_testimonial.style-3 .sigma_testimonial-body{
  padding: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 140px;
  top: 50%;
  transform: translateY(-50%);
}
.sigma_testimonial.style-3 .sigma_testimonial-inner cite{
  display: block;
  border-top: 1px solid #efefef;
  padding: 10px 30px;
  text-transform: none;
}


/* Style 4 */

.sigma_testimonial-sec.style-4{
  padding-top: 230px;
}

.sigma_testimonial.style-4{
  box-shadow: none;
  padding: 40px;
  background-color: #022147;
  text-align: left;
  position: relative;
}

.sigma_testimonial.style-4:after{
  content: "";
  position: absolute;
  right: -118%;
  top: 0;
  width: 120%;
  z-index: -1;
  height: 100%;
  background-color: #022147;
}

.sigma_testimonial.style-4 .icon{
  line-height: 1;
  display: flex;
  font-size: 50px;
  color: #F74F22;
}

.sigma_testimonial.style-4 p{
  color: #fff;
  font-size: 18px;
  margin: 10px 0 30px;
}

.sigma_testimonial.style-4 .sigma_testimonial-author cite{
  color: #fff;
  font-size: 18px;
}

.sigma_testimonial.style-4 .sigma_testimonial-slider-1 .slick-dots{
  justify-content: flex-end;
  margin-top: 12px;
}

.sigma_testimonial.style-4 .sigma_testimonial-image{
  position: relative;
  margin-top: -150px;
}

.sigma_testimonial.style-4 .sigma_testimonial-image img{
  transition: 0.3s;
  transform: translateY(0);
}
.sigma_testimonial.style-4 .sigma_testimonial-image img:hover{
  transform: translateY(-6px);
}

.sidebar-widget.widget-ad{
  position: relative;
}
.sidebar-widget.widget-ad a div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  min-width: 80%;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #F74F22;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  line-height: 1.4;
}
.sidebar-widget.widget-ad a div span{
  font-size: 14px;
  display: block;
  text-transform: none;
}

.sidebar-widget.widget-ig [class*="col-"]{
  padding: 0 2.5px;
}
.sidebar-widget.widget-ig .row{
  margin-left: -5px;
  margin-right: -5px;
}

/* counter */
.sigma_counter{
  position: relative;
  text-align: center;
  padding: 30px;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  flex-direction: column;
}
.sigma_counter::before{
  border-radius: 50%;
}
.sigma_counter p{
  font-size: 16px;
}
.sigma_counter *{
  color: #fff;
  margin: 0;
}
.sigma_counter .btn-link{
  margin-top: 20px;
  display: block;
}
.sigma_counter .btn-link:hover::before{
  background-color: #fff;
}
.sigma_counter h4{
  position: relative;
  font-size: 85px;
  line-height: 1;
  margin: 0 0 0;
}
.sigma_counter h4 span{
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: -10px;
}

.sigma_counter.counter-absolute{
  position: absolute;
  top: -9px;
  right: 35px;
  z-index: 4;
}

/* sermon */
.sigma_service{
  display: block;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 40px;
  text-align: left;
  transition: .3s;
  margin-bottom: 30px;
}

.sigma_service.border .sigma_service-thumb{
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef;
}
.sigma_service.border .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 10px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #A71B19;
  transition: .3s;
}
.sigma_service.border .sigma_service-thumb::after{
  content: '';
  position: absolute;
  bottom: 10px;
  left: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #A71B19;
  transition: .3s;
}

.sigma_service.primary-bg:hover,
.sigma_service.primary-bg{
  background-color: #A71B19;
  border: 0;
}
.sigma_service.secondary-bg:hover,
.sigma_service.secondary-bg{
  background-color: #022147;
  border: 0;
}

.sigma_service.border.primary-bg .sigma_service-thumb::after,
.sigma_service.border.primary-bg .sigma_service-thumb::before,
.sigma_service.border.secondary-bg .sigma_service-thumb::after,
.sigma_service.border.secondary-bg .sigma_service-thumb::before{
  background-color: #fff;
}

.sigma_service.border:hover .sigma_service-thumb::after{
  bottom: -4px;
  left: 47px;
}
.sigma_service.border:hover .sigma_service-thumb::before{
  top: -4px;
  right: 47px;
}

.sigma_service.bg-white{
  border: 0;
}

.sigma_service.style-1.bg-white:hover .btn-link,
.sigma_service.style-1.bg-white:hover p,
.sigma_service.style-1.bg-white:hover h5{
  color: #022147;
}
.sigma_service.style-1.bg-white:hover i{
  color: #F74F22;
}

.sigma_service i{
  font-size: 60px;
  color: #A71B19;
  line-height: 1;
  transition: .3s;
}
.sigma_service .btn-link{
  margin-top: 20px;
  display: block;
}
.sigma_service .btn-link i{
  font-size: 14px;
  color: #022147;
  margin-left: 5px;
}
.sigma_service-thumb{
  margin-bottom: 40px;
}
.sigma_service h5{
  margin-bottom: 10px;
  transition: .3s;
}
.sigma_service p{
  margin: 0;
  transition: .3s;
}
.sigma_service:hover{
  background-color: #F74F22;
  transform: translateY(-3px);
}
.sigma_service.style-1:hover .btn-link,
.sigma_service.style-1:hover i,
.sigma_service.style-1:hover p,
.sigma_service.style-1:hover h5{
  color: #fff;
}

/* Style 2 & 3 */
.sigma_service.style-3,
.sigma_service.style-2{
  background-color: transparent;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_service.style-3 .sigma_service-thumb,
.sigma_service.style-2 .sigma_service-thumb{
  position: relative;
  margin: 0;
  z-index: 1;
}

.sigma_service.style-3 .sigma_service-thumb img,
.sigma_service.style-2 .sigma_service-thumb img{
  width: 100%;
}

.sigma_service.style-3 i,
.sigma_service.style-2 i{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_service.style-3 .sigma_service-thumb::before,
.sigma_service.style-2 .sigma_service-thumb::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_service.style-3:hover i,
.sigma_service.style-3:hover .sigma_service-thumb::before,
.sigma_service.style-2:hover i,
.sigma_service.style-2:hover .sigma_service-thumb::before{
  opacity: 1;
  visibility: visible;
}

.sigma_service.style-3 h5,
.sigma_service.style-2 h5{
  margin-bottom: 20px;
}

.sigma_service.style-3 p,
.sigma_service.style-2 p{
  margin: 0;
}

.sigma_service.style-3 .sigma_service-body,
.sigma_service.style-2 .sigma_service-body{
  padding: 30px;
}
.sigma_service.style-3{
  border: 0;
}
.sigma_service.style-3 .sigma_service-body{
  position: relative;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  border: 0;
}
.sigma_service.style-3 .sigma_service-body::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #F74F22;
  transition: .3s;
}
.sigma_service.style-3:hover .sigma_service-body::before{
  width: 100%;
}

.sigma_service.style-2 .sigma_service-progress{
  margin-top: 20px;
}

.sigma_service.style-2 .sigma_service-progress .progress-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_service.style-2 .sigma_service-progress .progress-content p{
  color: #022147;
  font-size: 16px;
  font-weight: 500;
}

.sigma_service.style-2 .sigma_service-progress .sigma_progress{
  margin-top: 15px;
}

.sigma_service.style-2 .sigma_service-progress .progress{
  height: 8px;
  background-color: #efefef;
}

.sigma_service.style-2 .sigma_btn-custom{
  margin-top: 30px;
}


/* Sermon Box */

.sigma_sermon-box-wrapper{
  position: relative;
  margin-top: -200px;
}

.sigma_sermon-box .sigma_sermon-image img{
  width: 100%;
}

.sigma_sermon-box .sigma_box{
  padding: 45px 30px;
  margin: 0 0 30px;
}

.sigma_sermon-box .subtitle{
  display: flex;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.sigma_sermon-box .sigma_sermon-info{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sigma_sermon-box .sigma_sermon-info .sigma_sermon-date{
  color: #022147;
  font-weight: 600;
  font-size: 16px;
  margin-right: 50px;
}

.sigma_sermon-box .sigma_sermon-info .sigma_sermon-date span{
  color: #A71B19;
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  display: block;
  text-align: center;
  margin-bottom: 3px;
}

.sigma_sermon-box .sigma_sermon-info li{
  display: flex;
  align-items: center;
  margin: 0;
}

.sigma_sermon-box .sigma_sermon-info li + li{
  margin-top: 10px;
}

.sigma_sermon-box .sigma_sermon-info li i{
  display: flex;
  color: #A71B19;
  margin-right: 12px;
}

.sigma_sermon-box .sigma_sm.square{
  margin-top: 20px;
}

.sigma_sermon-box .sigma_sm.square li a{
  border: 2px solid #efefef;
  color: #767e88;
  font-size: 20px;
}

.sigma_sermon-box .sigma_sm.square li a:hover{
  background-color: transparent;
  border-color: #F74F22;
  color: #F74F22;
}

.sigma_audio-player {
  overflow: hidden;
  padding: 20px 0 0;
  display: flex;
  align-items: center;
}

.sigma_audio-player .player-controls progress {
  width: 78%;
}

.sigma_audio-player .player-controls progress[value] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #e7e3db;
  color: blue;
  height: 9px;
  border: 0;
}

.sigma_audio-player .small,
small {
  font-size: 80%;
  font-weight: 400;
  color: #F74F22;
  font-size: 14px;
}

.sigma_audio-player .player-controls progress[value]::-webkit-progress-bar {
  background-color: #e7e3db;
  border-radius: 2px;
  border: 0;
  color: #F74F22;
}

.sigma_audio-player .player-controls progress::-webkit-progress-value {
  background-color: #F74F22;
}

.sigma_audio-player .player-controls p {
  font-size: 1.6rem;
}

.sigma_audio-player #play-btn {
  width: 55px;
  height: 55px;
  margin: 0 15px 0 0;
  background: #ffdbd3;
  text-align: center;
  color: #F74F22;
  line-height: 55px;
  cursor: pointer;
}

.sigma_audio-player .next_prev {
  width: 55px;
  height: 55px;
  background: #ffdbd3;
  text-align: center;
  color: #F74F22;
  line-height: 55px;
  cursor: pointer;
}

.sigma_audio-player #play-btn .fa-pause,
.sigma_audio-player #play-btn.pause .fa-play {
  display: none;
}

.sigma_audio-player #play-btn.pause .fa-pause {
  display: inline-block;
}

.player-controls.scrubber {
  width: 70%;
  background: #ffdbd3;
  padding: 15px 17px;
  margin-right: 15px;
}

.player-controls.scrubber .fa {
  color: #F74F22;
}

/* Details */
.sigma_post-single-thumb .sigma_icon-block,
.sigma_post-single-thumb .sigma_sermon-box .sigma_box{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.sigma_post-single-thumb .sigma_sermon-box .sigma_audio-player .player-controls progress{
  width: 72%;
}


/* Client Reviews & Clients & Experience */
.sigma_experience{
  padding: 0 20px;
}
.sigma_experience-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sigma_experience-item strong{
  min-width: 170px;
}
.sigma_experience-item strong + span{
  margin-right: auto;
}
.sigma_experience-item + .sigma_experience-item{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}
.sigma_client-review{
  margin-bottom: 30px;
}
.sigma_box{
  padding: 30px;
  margin: 50px 0;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.sigma_box-lg{
  padding: 40px;
}
.sigma_box.box-lg{
  padding: 40px;
}
.sigma_box-absolute{
  position: absolute;
  top: -13px;
  left: -50px;
  width: calc(100% + 100px);
  z-index: 1;
}

.sigma_box-progress{
  position: relative;
}
.sigma_box-progress i{
  font-size: 110px;
  opacity: .2;
  position: absolute;
  line-height: 1;
  top: 20px;
  right: 20px;
}

.sigma_client{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: .3s;
  max-width: 90%;
  margin: 0 auto 30px;
}

.sigma_client:hover{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,.4));
}
.sigma_client img{
  transition: .3s;
}
.sigma_client:hover img{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,.1));
}

/* Portfolio */
.sigma_portfolio-item{
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}
.sigma_portfolio-item img{
  width: 100%;
}
.sigma_portfolio-item span{
  color: #fff;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.sigma_portfolio-item .sigma_portfolio-item-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: .3s;
  padding: 20px;
}
.sigma_portfolio-item:hover .sigma_portfolio-item-content{
  background-color: rgba(0,0,0,.4);
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner{
  margin-top: auto;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5{
  margin: 0 0 10px;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner p{
  margin: 0;
  color: #f4f4f4;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a{
  color: #fff;
}
.sigma_portfolio-item .sigma_portfolio-item-content-inner h5 a:hover{
  color: #F74F22;
}
.sigma_portfolio-item .sigma_portfolio-item-content i{
  font-size: 15px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  transition: .3s;
  z-index: 3;
  background-color: #A71B19;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio-item:hover .sigma_portfolio-item-content i{
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.sigma_portfolio-item:hover  span,
.sigma_portfolio-item:hover .sigma_portfolio-item-content-inner{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.filter-items{
  position: relative;
  max-width: 700px;
  margin: 0 auto 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
}
.filter-items.left{
  margin-left: 0;
}
.filter-items h5{
  position: relative;
  transition: .3s;
  padding: 20px 40px;
  font-size: 14px;
  color: #777;
  border-bottom: 3px solid transparent;
  margin: 0;
  cursor: pointer;
}
.filter-items h5 + h5::before{
  content: '';
  width: 2px;
  height: 15px;
  background-color: #efefef;
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.filter-items h5.active{
  border-bottom-color: #A71B19;
  color: #A71B19;
}
.filter-items h5:not(.active):hover{
  color: #022147;
}

/* Style 2 */
.sigma_portfolio-item.style-3 .sigma_portfolio-item-content,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content{
  position: relative;
  opacity: 1;
  visibility: visible;
  top: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: auto;
  left: auto;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
  background-color: #fff;
}
.sigma_portfolio-item.style-3:hover .sigma_portfolio-item-content,
.sigma_portfolio-item.style-2:hover .sigma_portfolio-item-content{
  background-color: #fff;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content i{
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  color: #777;
  background-color: transparent;
}

.sigma_portfolio-item.style-3:hover .sigma_portfolio-item-content i,
.sigma_portfolio-item.style-2:hover .sigma_portfolio-item-content i{
  bottom: auto;
  transform: translateX(5px);
  color: #F74F22;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  margin-top: 0;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner h5,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner h5{
  margin: 0;
  font-size: 16px;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner h5 a,
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner h5 a{
  color: #022147;
}

.sigma_portfolio-item.style-3 .sigma_portfolio-item-content{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: -45px;
}

/* Portoflio Details */
.sigma_post-single-thumb .sigma_box{
  position: absolute;
  top: 100px;
  right: 0px;
  margin: 0;
  min-width: 300px;
}

.sigma_list-item + .sigma_list-item{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
}
.sigma_list-item label{
  display: block;
  margin: 0;
}
.sigma_list-item .sigma_sm li a{
  color: #767e88;
}
.sigma_list-item .sigma_sm li a:hover{
  color: #F74F22;
}

/* Custom Map */
.map-markers{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.map-marker{
  position: relative;
  cursor: pointer;
}
.map-marker::before{
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
}
.map-marker > span{
  width: 6px;
  display: block;
  height: 6px;
  background-color: #F74F22;
  border-radius: 50%;
}
.map-marker > img{
  width: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.map-marker-content{
  position: absolute;
  top: -15px;
  left: calc(100% + 15px);
  transition: .3s;
  opacity: 0;
  min-width: 200px;
  visibility: hidden;
  padding: 10px;
  background-color: #282828;
  color: #dedede;
  font-size: 12px;
  z-index: 3;
}
.map-marker-content::before{
  content: '';
  position: absolute;
  top: 10px;
  width: 0;
  right: 100%;
  transform: rotate(360deg);
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #022147;
  border-bottom: 10px solid transparent;
}
.map-marker.right .map-marker-content::before{
  transform: rotate(0);
  right: 100%;
  left: auto;
}
.map-marker.right .map-marker-content{
  right: auto;
  left: calc(100% + 15px);
}
.map-marker-content p{
  color: #dedede;
  margin: 0 0 5px;
  font-size: 12px;
}
.map-marker:hover .map-marker-content{
  opacity: 1;
  visibility: visible;
}


.map-marker.marker-1{ /* Alemanha */
  position: absolute;
  top: 18%;
  right: 52%;
}
.map-marker.marker-2{ /* MS */
  position: absolute;
  top: 77%;
  right: 73%;
}
.map-marker.marker-3{ /* SC */
  position: absolute;
  top: 82%;
  right: 72%;
}
.map-marker.marker-4{ /* PR */
  position: absolute;
  top: 75%;
  right: 69%;
}
.map-marker.marker-5{ /* Itália */
  position: absolute;
  top: 26%;
  right: 48%;
}
.map-marker.marker-6{ /* Portugal */
  position: absolute;
  top: 28%;
  right: 56%;
}
.map-marker.marker-7{ /* EUA */
  position: absolute;
  top: 28%;
  right: 82%;
}.map-marker.marker-8{ /* SP */
  position: absolute;
  top: 74%;
  right: 70%;
}.map-marker.marker-9{ /* RJ */
  position: absolute;
  top: 73%;
  right: 69%;
}


/* Donation Form */


.sigma_donation-form .input-group .sigma_btn-custom.btn-sm{
  font-size: 24px;
  line-height: 1.5;
}

.sigma_donation-form .sigma_select-amount{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 0;
}

.sigma_donation-form .sigma_select-amount li{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  border: 2px solid #efefef;
  cursor: pointer;
  transition: 0.3s;
}

.sigma_donation-form .sigma_select-amount li:hover,
.sigma_donation-form .sigma_select-amount li.active{
  background-color: #F74F22;
  color: #fff;
  border-color: #F74F22;
}

.sigma_donation-form .sigma_select-amount li + li{
  margin-left: 15px;
}
/*dks*/ 
.custom-form .form-control::placeholder,
.sigma_arrows.style-2 .slick-arrow:hover,
.form-control.transparent::placeholder,
.form-control.transparent,
.sigma_footer.sigma_footer-dark .footer-widget,
.sigma_footer.sigma_footer-dark .footer-widget.widget-recent-posts .sigma_recent-post-body > a,
.sigma_footer.sigma_footer-dark p, .sigma_footer.sigma_footer-dark .footer-widget ul li a,
.sigma_sm.square.light li a:hover i,
.sigma_sm.square li a:hover i{
  color: #fff;
}
.sigma_post-share .sigma_sm li a:hover i,
.sigma_portfolio-item.style-3:hover .sigma_portfolio-item-content h5 a, 
.sigma_portfolio-item.style-2:hover .sigma_portfolio-item-content h5 a,
.sigma_footer.sigma_footer-dark .footer-widget ul li a:hover{
  color: #A71B19;
}
.blockquote.bg-transparent {
    color: #022147;
}
.sigma_icon-block.icon-block-2 .sigma_icon-block-content i{
  display: none;
}
.sigma_arrows .slick-arrow{
  border: 1px solid #fff;
  color: #fff;
}
.sigma_arrows .slick-arrow:hover{
  border-color: #F74F22;
}
.sigma_arrows.style-2 .slick-arrow{
  border: none;
  color: #777;
}
.sigma_img-box img,
.img-group-inner img,
.sidebar-widget.widget-ad img{
  width: 100%;
}
.sidebar-widget.widget-speakers .card:last-child{
  margin-bottom: -20px;
}
.custom-form .form-group i, 
.custom-form .form-control{
  border-color: #fff;
}
.sigma_img-box img + img{
  margin-top: 24px;
}
.sigma_product-single-thumb img{
  width: 100%;
}
.sigma_portfolio-item span, .sigma_portfolio-item .sigma_portfolio-item-content-inner {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.sigma_portfolio-item .sigma_portfolio-item-content {
    background-color: rgba(0,0,0,.4);
}
.sigma_portfolio-item .sigma_portfolio-item-content i {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}
.sigma_portfolio-item.style-3 i,
.sigma_portfolio-item.style-2 i{
  bottom: auto;
}

.current-day {
  background-color: yellow; /* Altere para a cor de destaque desejada */
}

.position_title {
  margin-bottom: 50px;
  text-align: center;
}

audio {
  width: 100%; 
  background-color: rgba(255, 87, 34, 0.6); 
  
}

audio::-webkit-media-controls-panel {
  background-color: rgba(255, 87, 34, 0.6); 
}

audio::-webkit-media-controls-play-button {
  background-color: #ffffff; 
  border-radius: 50%;
}

audio::-webkit-media-controls-volume-slider {
  background-color: rgba(255, 87, 34, 0); 
}
